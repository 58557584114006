import app from "firebase/compat/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";


var firebaseConfig = {
  apiKey: "AIzaSyD8lJA9t_S32GJMjTD8Xr3QBk9NvuVyMDg",
  authDomain: "medreact-cf09d.firebaseapp.com",
  databaseURL: "https://medreact-cf09d.firebaseio.com",
  projectId: "medreact-cf09d",
  storageBucket: "medreact-cf09d.appspot.com",
  messagingSenderId: "622421712499",
  appId: "1:622421712499:web:13f2814a314cdeea681406",
  Clientid: "622421712499-lu5csgu477ee5n5hf68f62pnmohr4ruu.apps.googleusercontent.com"
};
// Initialize Firebase

class Firebase {
  constructor() {
    // app.initializeApp(firebaseConfig);
    this.storage = app.storage();
    this.auth = app.auth();
    this.app = app;
    // this.database = app.firestore();

    
  }

  

  isInitialized(func) {
    return app.auth().onAuthStateChanged(func);
  }

  isOk() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  Logout() {
    return this.auth.signOut();
  }
}

/**
 * @static
 */
export let firebase = new Firebase();
