import React from 'react';
import './ChatBubble.css'; // CSS file for styling chat bubbles
import firebase from 'firebase/compat/app';
import 'firebase/database';
import  { auth, provider } from '../firebase.js';

import '../App.css';
class ChatBubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      },
      
    }






    this.dialog = [
      // Pre-recorded dialog data
      { id: 1, sender: 'Provider', message: 'Good morning Miss Henty Todd, how are you feeling today?' },
      { id: 2, sender: 'Patient', message: 'Hi doc, I\'m feeling much better now. My health has improved since our last visit.' },
      { id: 3, sender: 'Provider', message: 'That\'s great to hear! I remember you mentioned your goal of losing weight and improving your diet and exercise routine. How have things been going in that regard?' },
      { id: 4, sender: 'Patient', message: 'I\'ve been doing well with my diet and exercise. I\'ve been following the plan we discussed, and I\'m  happy to say that my weight has improved.' },
      { id: 5, sender: 'Provider', message: 'That\'s excellent! I\'m glad to hear that your efforts are paying off. Now, let\'s review your lab results and EKG from your recent visit.' },
      { id: 6, sender: 'Patient', message: 'Sure, I\'m interested to know how everything looks.' },
      { id: 7, sender: 'Provider', message: 'Your blood pressure is still well controlled, which is great news. However, I did notice a slight abnormality in your EKG. It would be recommended to have a baseline visit with a cardiologist to further evaluate this finding. Have you experienced any chest pain or discomfort lately?' },
      { id: 8, sender: 'Patient', message: 'No, I haven\'t had any chest pain. My blood pressure medication seems to be working well, and I feel fine overall.' },
      { id: 9, sender: 'Provider', message: 'That\'s good to know. We\'ll schedule a referral for you to see a cardiologist and establish a baseline for your abnormal EKG. Now, let\'s go over your lab results.' },
      { id: 10, sender: 'Patient', message: 'Alright, I\'m curious to see how my numbers have changed.' },
      { id: 11, sender: 'Provider', message: 'I\'m pleased to inform you that your cholesterol levels have improved. Your HbA1c is now at a healthy level of 5.4, indicating better control of your diabetes. Additionally, your thyroid function enzymes are within the normal range.' },
      { id: 12, sender: 'Patient', message: 'That\'s great news! I\'m glad to see the positive changes.' },
      { id: 13, sender: 'Provider', message: 'However, your labs also show low vitamin D levels, which have been consistent with the initial tests we conducted a year and three months ago. I recommend you continue taking the prescribed vitamin D medication to address this deficiency.' },
      { id: 14, sender: 'Patient', message: 'Alright, I\'ll make sure to continue taking it as prescribed.' },
      { id: 15, sender: 'Provider', message: 'Good. I also suggest repeating your labs in three months and then again in six months to monitor your progress. In the meantime, it\'s important for you to stay hydrated by drinking at least eight glasses of water daily to prevent constipation, urinary tract infections, and dehydration.' },
      { id: 16, sender: 'Patient', message: 'Understood, I\'ll keep that in mind and stay hydrated.' },
      { id: 17, sender: 'Provider', message: 'Overall, you\'re doing well, and I\'m pleased with the improvements in your health. The cardiology referral will help establish a baseline visit for your abnormal EKG. If you have any further questions or concerns, please don\'t hesitate to reach out.' },
      { id: 18, sender: 'Patient', message: 'Thank you, doctor. I appreciate your thorough care and guidance. I\'ll make sure to follow up with the cardiology referral.' },
      { id: 19, sender: 'Provider', message: 'You\'re welcome. I\'m here to support you on your health journey. Take care, Miss Henty Todd, and I\'ll see you soon.' },
      { id: 20, sender: 'Patient', message: 'Thank you, doctor. Take care as well, and see you soon.' },
      // { id: 34, sender: 'Provider', message: 'Overall, you have a lot of health conditions to manage, but I want you to know that I\'m  here to help you every step of the way. We\'ll work together to come up with a plan that addresses all of your concerns and helps you achieve optimal health.' },
      { id: 35, sender: '', message: 'THE DIALOG with PROVIDER/PATIENT COMPLETED, COMPLETE YOUR NOTES WITH DIAGNOSES, HISTORY OF EACH ISSUES AND A PLAN' },
      // Add more dialog messages as needed
      // Add more dialog messages as needed
    ];

    this.state = {
      displayedMessages: [], // Track displayed messages
      currentMessageIndex: 0, // Track the index of the current message
      isPaused: false, // Track pause/resume state
      likes: {}, // Track the number of likes for each message
      likesCount: {},
      displayButtonClicked: false,
    };

    this.timeouts = []; // Track timeouts for cleanup

    this.firebaseConfig = {
      // Add your Firebase configuration here
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(this.firebaseConfig);
    }

    this.firebaseDatabase = firebase.database();
  }

  componentDidMount() {


    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
    }); 
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   }
    // });

    this.loadLikesFromFirebase();

    this.displayMessagesInSuccession();
    this.loadLikesFromFirebase();
  }

  componentWillUnmount() {
    this.clearTimeouts();

      // Unsubscribe from Firebase listener
   this.unsubscribeFromAuth();
   this.firebaseDatabase.ref('likes').off();
  }
  
  
  handleDisplayMessages = () => {

    
    this.setState({
      displayedMessages: this.dialog,
      displayButtonClicked: true,
    });

    this.setState({ isPaused: true });
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.timeouts = [];
  };

  displayMessagesInSuccession() {
    const { currentMessageIndex, isPaused } = this.state;
  
    if (currentMessageIndex >= this.dialog.length || isPaused) {
      return;
    }
    if (currentMessageIndex >= this.dialog.length - 1) {
      return; // Do not perform any action if the last message is displayed
    }
    const message = this.dialog[currentMessageIndex];
    const timeout = setTimeout(() => {
      this.setState(
        (prevState) => ({
          displayedMessages: prevState.displayedMessages.concat(message),
          currentMessageIndex: prevState.currentMessageIndex + 1,
        }),
        () => {
          this.displayMessagesInSuccession();
        }
      );
    }, 4000); // Delay each message by 1 second
  
    this.timeouts.push(timeout);
  }
  
  clearTimeouts() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
  }
  handlePauseResume = () => {
    const { currentMessageIndex } = this.state;
    if (currentMessageIndex >= this.dialog.length - 1) {
      return; // Do not perform any action if the last message is displayed
    }
    this.setState(
      (prevState) => ({
        isPaused: !prevState.isPaused,
      }),
      () => {
        if (!this.state.isPaused) {
          this.displayMessagesInSuccession();
        }
      }
    );
  };
  handleLike = (messageId) => {
    const { likes, user } = this.state;
  
    
    this.setState(
      (prevState) => ({
        likes: {
          ...prevState.likes,
          [messageId]: {
            ...(prevState.likes[messageId] ? prevState.likes[messageId] : {}),
            [user.uid]: {
              displayName: user.displayName,
              liked: true,
            },
          },
        },
      }),
      () => {
        // Update the Firebase database with the updated likes
        const { likes } = this.state;
        const messageRef = firebase.database().ref(`messages/${messageId}/likes`);
        messageRef.set(likes[messageId]);
      }
    );
  };
  
  loadLikesFromFirebase() {
    this.firebaseDatabase.ref('likes').on('value', (snapshot) => {
      const likes = snapshot.val() || {};
      this.setState({ likes });
  
      // Calculate the likes count
      const likesCount = {};
      Object.values(likes).forEach((like) => {
        Object.keys(like).forEach((key) => {
          likesCount[key] = (likesCount[key] || 0) + 1;
        });
      });
      this.setState({ likesCount });
    });
  }
  saveLikesToFirebase() {
    const { likes } = this.state;
    this.firebaseDatabase.ref('likes').set(likes);
  }

 

  renderChatBubbles() {
    const { displayedMessages, likes, user } = this.state;
  
    return displayedMessages.map((message) => {
      const bubbleClass = message.sender === 'Provider' ? 'bubble Provider' : 'bubble Patient';
      const isLikedByUser = likes[message.id] && likes[message.id][user.uid]; // Check if the current user liked the message
  
      return (
        <div key={message.id} className={bubbleClass} style={{ color: 'white', position: 'relative' }}>
          {message.message}
          <br /><br />
          <div style={{ position: 'absolute', bottom: '2px', left: '8px' }}>
 
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={isLikedByUser ? 'red' : (message.sender === 'Provider' ? 'white' : 'grey')}
      viewBox="0 0 16 16"
      style={{
        display: 'block',
        margin: '0 auto',
        left: '15px',
        top: '5px'
      }}
      onClick={() => this.handleLike(message.id)}
    >
      <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm6 3.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
    </svg>
    {/* <span style={{ color:'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{this.state.likesCount[message.id] || 0}</span> */}

</div>
          <div style={{ position: 'absolute', bottom: '2px', right: '2px' }}>
            <button
              style={{
                width: '60px',
                height: '20px',
                fontFamily: 'sans-serif',
                fontSize: '9px',
                color: message.sender === 'Provider' ? 'white' : 'grey',
                backgroundColor: message.sender === 'Provider' ? 'grey' : 'lightblue',
                border: '0',
                borderRadius: '5px',
                boxShadow: '0 2px rgb(40, 127, 184)',
                cursor: 'pointer',
                outline: '0',
              }}
              onClick={() => this.handleLike(message.id)}
            >
              ({message.sender})
            </button>
          </div>
        </div>
      );
    });
  }
  


  
  render() {
    const { isPaused, displayButtonClicked } = this.state;
  
    return (
      <div className="chat-container" style={{ background: '#252d4a', marginTop: '10px' }}   >
      {this.renderChatBubbles()}
      <div>
        {!displayButtonClicked && ( // Only show the resume button if display button is not clicked
          <button
            className="badge badge-info"
            style={{
              backgroundColor: 'light green',
              color: 'white',
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              marginRight: '10px'
            }}
            onClick={this.handlePauseResume}
          >
            {isPaused ? 'Resume' : 'Pause'}
          </button>
        )}
    
        <button
          className="badge badge-info"
          style={{
            backgroundColor: 'light grey',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
          }}
          onClick={this.handleDisplayMessages}
        >
          Display Messages
        </button>
      </div>
    </div>
    );
  }
}

export default ChatBubble;
