import React, { Component } from 'react';
// import styled from "styled-components";
import './App.css';
import unsplash from './images/unsplash.jpg'
import './Modal.css';
import firebase, { auth, provider } from './firebase.js';
import Modal from 'react-modal';
import Psh from './Modals/psh'
import Diet from './Modals/diet';
import Meds from './Modals/meds0';
import Health from './Modals/health';
import swal from 'sweetalert';
import { faDownload, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';





class Leftside001 extends Component {

  constructor(props) {
    super();
    this.state = {
      isHovered: false,
      currentItem: '',
      currentItem1: '',
      allergies: '',
      pmh: '',
      psh: '',
      username: '',
      Left: [],
      Left0: [],
      Left1: [],
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      },
      modelText01: '',
      modalIsOpen: false,
      modalIsOpen0: false,
      modalIsOpen1: false,
      displayButtonClicked: true,
      displayButtonClicked2: true,
      displayButtonClicked3: true,
      showDetails: false

    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit0 = this.handleSubmit0.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);



  }

 
  handleMouseEnter = () => {
    this.setState({ isHovered: true });
    // this.hoverTimeout = setTimeout(() => {
    //   swal("Allergies", "...Type of Allergy and Symptoms! e.g Pollen, Itchy eyes and runny nose");
    // }, 500);
  };

  handleMouseLeave = () => {
    clearTimeout(this.hoverTimeout);
    this.setState({ isHovered: false });
  };
 
  toggleDetails = () => {
    this.setState(prevState => ({
      showDetails: !prevState.showDetails,
    }));
  };


  calculateBMI = (weight, height) => {
    const bmi = (weight / (height * height)) * 703; // BMI formula for weight in lbs and height in inches
    return bmi.toFixed(2); // Return BMI with 2 decimal places
  };

  handleDisplayMessages = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

      if (this.state.displayButtonClicked) {
        // If the button is already clicked, set the state to false to hide the content
        this.setState({
          displayButtonClicked: false
        });
      } else {
        // If the button is not clicked, set the state to true to display the content
        this.setState({
          displayButtonClicked: true
        });
      }
    }



  };



  handleDisplayMessages2 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

      if (this.state.displayButtonClicked2) {
        // If the button is already clicked, set the state to false to hide the content
        this.setState({
          displayButtonClicked2: false
        });
      } else {
        // If the button is not clicked, set the state to true to display the content
        this.setState({
          displayButtonClicked2: true
        });
      }
    }

  };

  handleDisplayMessages3 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

      if (this.state.displayButtonClicked3) {
        // If the button is already clicked, set the state to false to hide the content
        this.setState({
          displayButtonClicked3: false
        });
      } else {
        // If the button is not clicked, set the state to true to display the content
        this.setState({
          displayButtonClicked3: true
        });
      }
    }

  };


  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }


  handleSubmit(e) {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
      const Left1Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left1`);
      const item1 = {
        psh: this.state.psh,
        user: this.state.user.displayName || this.state.user.email,
        selectedProp: this.props.selectedProp
      }


      Left1Ref.push(item1);
      this.setState({
        psh: '',
        username: ''

      });

    }

  }


  handleSubmit0(e) {


    const LeftRef = firebase.database().ref(`users/${this.state.user.displayName}/Left`);
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

      const item = {
        allergies: this.state.allergies,
        user: this.state.user.displayName || this.state.user.email,
        selectedProp: this.props.selectedProp
      }

      LeftRef.push(item);

      this.setState({
        allergies: '',
        username: '',




      });

    }


  }

  handleSubmit1(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
      const Left0Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left0`);
      const item0 = {
        pmh: this.state.pmh,
        user: this.state.user.displayName || this.state.user.email,
        selectedProp: this.props.selectedProp
      }

      Left0Ref.push(item0);

      this.setState({

        pmh: '',
        username: '',


      });
    }

  }


  _isMounted = false;


  componentDidMount(userId) {

    this._isMounted = true;

    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (this._isMounted && user) {
        this.setState({ user });
      }
    });

    const LeftRef = firebase.database().ref(`users`);
    LeftRef.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left) {
          let Left = user.Left;
          for (let itemId in Left) {
            let item = Left[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              allergies: item.allergies,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left: newState
      });
    });

    const Left0Ref = firebase.database().ref('users');
    Left0Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left0) {
          let Left0 = user.Left0;
          for (let itemId in Left0) {
            let item = Left0[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              pmh: item.pmh,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left0: newState
      });
    });




    const Left1Ref = firebase.database().ref('users');
    Left1Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left1) {
          let Left1 = user.Left1;
          for (let itemId in Left1) {
            let item = Left1[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              psh: item.psh,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left1: newState
      });
    });

  }


  handleEditChange(event, itemId) {
    const { value } = event.target;
    this.setState((prevState) => ({
      Left: prevState.Left.map((item) => {
        if (item.itemId === itemId) {
          return { ...item, allergies: value };
        }
        return item;
      }),
    }));


  }

  startEditItem(itemId) {
    this.setState({ editingItemId: itemId });
  }

  submitEditItem(itemId) {
    const updatedAllergies = this.state.Left.find((item) => item.itemId === itemId).allergies;
    const itemRef = firebase.database().ref(`users/${this.state.user.displayName}/Left/${itemId}`);

    itemRef.update({ allergies: updatedAllergies })
      .then(() => {
        console.log('Item updated successfully.');
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.error('Error updating item:', error);
      });
  }



  handleEditChange2 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left0: prevState.Left0.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            pmh: updatedValue,
          };
        }
        return item;
      }),
    }));
  };

  startEditItem2 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };

  submitEditItem2 = (itemId) => {
    const updatedItem = this.state.Left0.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left0/${itemId}`);

    userRef.update({ pmh: updatedItem.pmh })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };


  handleEditChange3 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left1: prevState.Left1.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            psh: updatedValue,
          };
        }
        return item;
      }),
    }));
  };

  startEditItem3 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };

  submitEditItem3 = (itemId) => {
    const updatedItem = this.state.Left1.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left1/${itemId}`);

    userRef.update({ psh: updatedItem.psh })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };


  componentWillUnmount() {
    this._isMounted = false;
    // Unsubscribe from Firebase listener
    firebase.database().ref('users').off();

    this.unsubscribeFromAuth();
  }

  removeItem(userId, itemId) {

    const itemRef = firebase.database().ref(`users/${userId}/Left/${itemId}`);
    itemRef.remove();

    const itemRef0 = firebase.database().ref(`users/${userId}/Left0/${itemId}`);
    itemRef0.remove();

    const itemRef1 = firebase.database().ref(`users/${userId}/Left1/${itemId}`);
    itemRef1.remove();
  }

  render() {
    const { focussed, value, error, label } = this.state;
    const { id, type, locked } = this.props;
    const { displayButtonClicked } = this.state;
    const { displayButtonClicked2 } = this.state;
    const { displayButtonClicked3 } = this.state;
    const { showDetails } = this.state;
    const { isHovered } = this.state;
    const styles = {

      slide: {
        fontSize: "12px",
        maxWidth: "100%",
        overflow: "hidden",
        display: "block",
        lineHeight: "1",
        border: "none",
        borderBottom: "none",
        outline: "none",
      },

      svg: {
        display: 'block',
        margin: '0 auto',
        left: '10px',
        top: '5px',
        bottom: '50px',
      },

      radio: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px', // Adjust the top padding to make the items thinner
        height: '20px', // Adjust the height of each item
        width: '100%', // Ensure the items have the same width

      },

      lists: {
        height: '20px',
        width: '300px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '12px',
        paddingLeft: '5px',
        color: 'grey',
        // width: '98%',
        marginLeft: '10px',
        marginTop: '20px', // Set the font color to grey
        whiteSpace: 'pre-wrap', // Allow wrapping at whitespace
        overflowWrap: 'break-word',
      },


      userinfo: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '10px',
        position: 'relative',
        border: '1px solid',
        width: '98%', 
        borderRadius: '25px',
        overflow: 'hidden' // Add this line
      },
      userInfoLine: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '1px',
        background: 'linear-gradient(to right, purple, green)'
      },
      Input: {
        fontSize: '12px',
        paddingLeft: '14px',
        cursor: 'pointer'
      }

    };


    return (


      <  >

        {this.state.user ?
          <div>
            <div className="card_item" style={{...styles.userinfo }}>
              <div style={styles.userInfoLine}></div>
              <div className="card_inner">
                {/* <img src={curElem.avatar_url} alt="" /> */}
                <div className="userName">Vitals</div>
                <div className="userUrl">
                  {this.props.selectedProp === 'new patient' ? (
                    'New Patient'
                  ) : this.props.selectedProp === 'annual' ? (
                    'Annual Patient'
                  ) : this.props.selectedProp === 'follow up' ? (
                    'Follow-up Patient'
                  ) : (
                    'Please select a chart'
                  )}
                </div>

                <div className="detail-box">

                  {this.props.selectedProp === 'new patient' && (
                    <>
                      <div className="row">
                      
                        <a>
                          <div className="gitDetail">
                            <span>BP</span>141/78
                          </div>
                          <div className="gitDetail">
                            <span>Temp</span>98.0F
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>Weight</span>163 lbs
                          </div>
                          <div className="gitDetail">
                            <span>Height</span>65 in
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>HR</span>62
                          </div>
                          <div className="gitDetail">
                            <span>RR</span>16
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>O2</span>98%
                          </div>
                          <div className="gitDetail">
                            <span>BMI</span>{' '}
                            {this.calculateBMI(163, 65)} {/* Call the BMI calculator function */}
                          </div>
                        </a>
                      </div>
                    </>





                  )}
                  {this.props.selectedProp === 'annual' && (
                    <>
                      <div className="row">
                     
                        <a>
                          <div className="gitDetail">
                            <span>BP</span>131/72
                          </div>
                          <div className="gitDetail">
                            <span>Temp</span>97.6F
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>Weight</span>178 lbs
                          </div>
                          <div className="gitDetail">
                            <span>Height</span>65 in
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>HR</span>60
                          </div>
                          <div className="gitDetail">
                            <span>RR</span>16
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>O2</span>98%
                          </div>
                          <div className="gitDetail">
                            <span>BMI</span>{' '}
                            {this.calculateBMI(168, 65)} {/* Call the BMI calculator function */}
                          </div>
                        </a>
                      </div>
                    </>





                  )}
                  {this.props.selectedProp === 'follow up' && (
                    <>
                    
                      <div className="row">
                    
                        <a>
                          <div className="gitDetail">
                            <span>BP</span>130/73
                          </div>
                          <div className="gitDetail">
                            <span>Temp</span>98.5F
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>Weight</span>162 lbs
                          </div>
                          <div className="gitDetail">
                            <span>Height</span>65 in
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>HR</span>63
                          </div>
                          <div className="gitDetail">
                            <span>RR</span>16
                          </div>
                        </a>
                        <a>
                          <div className="gitDetail">
                            <span>O2</span>98%
                          </div>
                          <div className="gitDetail">
                            <span>BMI</span>{' '}
                            {this.calculateBMI(162, 65)} {/* Call the BMI calculator function */}
                          </div>
                        </a>
                      </div>
                    </>



                  )}

                </div>
                <div>
      
                {this.props.selectedProp === 'new patient' &&  (
                    <>
                      {showDetails ? (

        
<div className="detail-box">
<div className='row'>
 <a>
   <div className="gitDetail">
     <span>Age</span> 41
   </div>
  
 </a>
 <a>
<div className="gitDetail">
     <span>Gender</span> female
   </div>

 </a>
 <div>
   {/* Additional patient details */}
 </div>
</div>
</div>
) : (
<div>
 <button className="seeMore" onClick={this.toggleDetails}>
   ShowMore
 </button>
</div>
)}
                    </>





                  )}
       
       {this.props.selectedProp === 'annual' && (
                    <>
                      {showDetails ? (

        
<div className="detail-box">
<div className='row'>
 <a>
   <div className="gitDetail">
     <span>Age</span> 42
   </div>
  
 </a>
 <a>
<div className="gitDetail">
     <span>Gender</span> female
   </div>

 </a>
 <div>
   {/* Additional patient details */}
 </div>
</div>
</div>
) : (
<div>
 <button className="seeMore" onClick={this.toggleDetails}>
   ShowMore
 </button>
</div>
)}
                    </>





                  )}

{this.props.selectedProp === 'follow up' && (
                    <>
                      {showDetails ? (

        
<div className="detail-box">
<div className='row'>
 <a>
   <div className="gitDetail">
     <span>Age</span> 42
   </div>
  
 </a>
 <a>
<div className="gitDetail">
     <span>Gender</span> female
   </div>

 </a>
 <div>
   {/* Additional patient details */}
 </div>
</div>
</div>
) : (
<div>
 <button className="seeMore" onClick={this.toggleDetails}>
   ShowMore
 </button>
</div>
)}
                    </>





                  )}


    </div>
 

              </div>
            </div>

<div  style={{ ...styles.userinfo,
             borderBottom: '2px solid rgb(121, 165, 220)',
    outline: 'none',
    boxShadow: '0 0 5px rgba(121, 165, 220, 0.5)',
    backgroundBlendMode: 'multiply',
    mixBlendMode: 'screen',
    backdropFilter: 'brightness(1.2) contrast(0.9) saturate(1.2)',
    borderLeft: '5px solid orange',
    position: 'relative',
    display: 'flex', // Make it a flex container
    width: '100%',
    height: '300px',
  }}>
    {/* Icons container in a column */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 1, }}>
    <div>
    <FontAwesomeIcon icon={faDownload} />
  </div>
  <div>
    <FontAwesomeIcon icon={faFileAlt} />
  </div>
 </div>
 <div
  className="UserInfo"
  style={{
    ...styles.userinfo,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    backgroundColor: 'rgb(121, 165, 220)',
    zIndex: 2,
    transform: this.state.isHovered ? 'translateX(10%)' : 'translateX(0)',
    height: '100%',
    overflowY: 'auto',
 
  }}
  onMouseEnter={this.handleMouseEnter}
  onMouseLeave={this.handleMouseLeave}
>    
             <div
    className="UserInfoLine"
    style={{
      ...styles.userInfoLine,
     
     
    
    }}
  ></div>
              <div style={{ color: 'white', position: 'relative' }}>
              <label
        htmlFor="allergies"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={() => swal("Allergies", "...Type of Allergy and Symptoms! e.g Pollen, Itchy eyes and runny nose")}
      >
        Allergies:
      </label>
</div>

              {displayButtonClicked && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                          {this.state.editingItemId === item.itemId ? (
                            <textarea
                              id="allergies"
                              placeholder="Enter your allergies here"
                              name="allergies"
                              value={item.allergies}
                              onChange={(event) => this.handleEditChange(event, item.itemId)}
                              onKeyPress={event => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem(item.itemId);
                                }
                              }}
                              style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ color: 'black', fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem(item.itemId)}
                            >
                              {item.allergies}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}
     <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                  {/* <label htmlFor="allergies" onClick={() => this.setState({ modalIsOpen: true })}> */}
                  
                  <input
                    id="allergies"
                    type="text"
                    placeholder="Enter your allergies here"
                    name="allergies"
                    onChange={this.handleChange}
                    value={this.state.allergies}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleSubmit0();
                      }
                    }}
                    style={{
                      ...styles.lists, background: 'transparent', color: 'black', width: '300px', height: '20px', resize: 'none',  borderRadius: '5px'
                    }}
                  />

                  <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </form>
            </div>
            </div>

            <div className="UserInfo" style={{ ...styles.userinfo, backgroundColor: 'rgb(121, 165, 220)',
            //  borderBottom: '2px solid rgb(121, 165, 220)',
    outline: 'none',
    boxShadow: '0 0 5px rgba(121, 165, 220, 0.5)',
    backgroundBlendMode: 'multiply',
    mixBlendMode: 'screen',
    backdropFilter: 'brightness(1.2) contrast(0.9) saturate(1.2)',
    borderLeft: '5px solid red',
     }}>
              <div style={styles.userInfoLine}></div>
              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                  <label htmlFor="pmh" onClick={() => swal("Medical Conditions", "...generate the medication condition based on the provided for Allergies, Vitals, BMI or PHQ-9, Meds or Labs results and Treatments")}>Medical Conditions:</label>
                  <input
                    id="pmh"
                    type="text"
                    placeholder="Enter your Medical Conditions here"
                    name="pmh"
                    onChange={this.handleChange}
                    value={this.state.pmh}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleSubmit1();
                      }
                    }}
                    style={styles.lists}
                  />
                  <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages2}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </form>



              {displayButtonClicked2 && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left0
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.pmh}
                              onChange={(event) => this.handleEditChange2(event, item.itemId)}
                              onKeyPress={event => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem2(item.itemId);
                                }
                              }}
                              style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem2(item.itemId)}
                            >
                              {item.pmh}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}



            </div>

            <div className="UserInfo" style={styles.userinfo}>
              <div style={styles.userInfoLine}></div>
              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>

                  <label htmlFor="psh" onClick={() => swal("Surgical Conditions", "...Surgical history and conditions with dates (year) of procedure including obstertic surgeries like c-sections et.c ")}>Surgical Conditions:</label>
                  <input
                    id="psh"
                    type="text"
                    placeholder="Enter your Surgical Conditions here"
                    name="psh"
                    onChange={this.handleChange}
                    value={this.state.psh}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleSubmit();
                      }
                    }}
                    style={styles.lists}
                  />
                  <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages3}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </form>



              {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen1} onRequestClose={() => this.setState({ modalIsOpen1: false })}>
                  <h2 key="modalTitle"> Surgical Conditions</h2>
                  <div className="search-results">
                    {this.state.Left1
                      .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                      .map((item) => (
                        <React.Fragment key={item.itemId}>
                          <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
                            <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })}
                              onClick={() => this.removeItem(item.userId, item.itemId)} />

                            <div style={{ fontSize: "12px", paddingLeft: "140" }}>
                              {item.psh}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                </Modal> */}
              {displayButtonClicked3 && (
                <div>
                  {this.state.Left1
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.itemId}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.itemId })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.psh}
                              onChange={(event) => this.handleEditChange3(event, item.itemId)}
                              onKeyPress={event => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem3(item.itemId);
                                }
                              }}
                              style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem3(item.itemId)}
                            >
                              {item.psh}
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}


            </div>






            <div className="CommunityCard">

              <Psh selectedProp={this.props.selectedProp} />
              <Diet selectedProp={this.props.selectedProp} />
              <Meds selectedProp={this.props.selectedProp} />
              <Health selectedProp={this.props.selectedProp} />




            </div>
          </div>
          :
          <p>You must be logged in to see the potluck list and submit to it.</p>
        }

/// Add diagnosis at visit and complete patient information from details of dialog with patient and provider </>
      // </WebView>  </view>



    );


  }
}
export default Leftside001;

