import React from 'react';
import './ChatBubble.css'; // CSS file for styling chat bubbles
import firebase from 'firebase/compat/app';
import 'firebase/database';
import  { auth, provider } from '../firebase.js';

import '../App.css';
class ChatBubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      },
      
    }






    this.dialog = [
      // Pre-recorded dialog data
      { id: 1, sender: 'Provider', message: 'Good morning Miss Henty Todd, how are you feeling today?' },
      { id: 2, sender: 'Patient', message: 'Hi doc, I\'m feeling a bit overwhelmed, to be honest. I have a lot going on health-wise and it\'s been tough to keep track of everything.' },
      { id: 3, sender: 'Provider', message: 'I\'m sorry to hear that. Let\'s take a look at your chart and make sure to complete everything you need to help you for your visit today.' },
      { id: 4, sender: 'Patient', message: 'So I\'m here for my regular office visit and I wanted to discuss a few health concerns with you.' },
      { id: 5, sender: 'Provider', message: 'Of course, I\'m here to address any concerns you have. I see that your blood pressure has been well controlled with lisinopril. Your vitals today show a blood pressure of 131/72. That\'s great! However, I noticed that you\'ve gained some weight since your last visit. You now weigh 178lbs. Have you made any changes to your diet or exercise routine?' },
      { id: 6, sender: 'Patient', message: 'Yes, I\'ve been watching my carb and fat intake lately, but the weight gain is still concerning to me. I would like to lose about 10-15lbs in the next month. Do you think that\'s achievable?' },
      { id: 7, sender: 'Provider', message: 'Losing 10-15lbs in a month can be challenging, but it\'s not impossible. I recommend focusing on a healthy and sustainable weight loss plan. It\'s important to combine a balanced diet with regular exercise. I can refer you to a nutritionist who can help you create a personalized meal plan. Additionally, I encourage you to continue monitoring your blood sugar levels since your last HbA1c was 6.4. I\'ve also noticed an increase in your cholesterol levels, so I recommend starting a low-dose cholesterol medication.' },
      { id: 8, sender: 'Patient', message: 'Okay, I\'ll make sure to follow your recommendations. I trust your expertise. By the way, I wanted to mention that I had a fall recently and hit my right knee. It\'s been quite painful. Is there anything I can do to alleviate the pain?' },
      { id: 9, sender: 'Provider', message: 'I\'m sorry to hear about your fall. To better understand the extent of your knee injury, I suggest we get an X-ray done. It will help us identify any potential fractures or other issues. In the meantime, I\'ll prescribe Tylenol 650mg to be taken every 8 hours as needed for pain relief. Please let me know if the pain worsens or if you experience any other symptoms.' },
      { id: 10, sender: 'Patient', message: 'Thank you, doc. I appreciate your prompt attention. One more thing, I had a negative mammogram last year. Should I repeat it this year?' },
      { id: 11, sender: 'Provider', message: 'Yes, it\'s recommended to have a mammogram done annually. I\'ll order a new mammogram for you this year to ensure we continue monitoring your breast health. Additionally, since you\'re due for your annual woman exam, I\'ll also provide a referral for you to see an OB/GYN for a pap exam.' },
      { id: 12, sender: 'Patient', message: 'Alright, I\'ll schedule those appointments as soon as possible. Is there anything else we need to address today?' },
      { id: 13, sender: 'Provider', message: 'Before we wrap up, I would like to repeat your annual labs and also request your consent for STD testing. It\'s essential to ensure your overall health and well-being. Please let me know if you have any concerns or questions about these tests.' },
      { id: 14, sender: 'Patient', message: 'No, I understand the importance of these tests. You have my consent. Thank you for your thorough care, as always.' },
      { id: 15, sender: 'Provider', message: 'You\'re welcome. It\'s my pleasure to provide you with comprehensive care. If you have any further questions or need any assistance, please don\'t hesitate to reach out. Take care, Miss Henty Todd, and I\'ll see you for your follow-up appointment.' },
      { id: 16, sender: 'Patient', message: 'Thank you, doctor. I appreciate your time and attention. Take care as well, and see you soon.' },
      // { id: 34, sender: 'Provider', message: 'Overall, you have a lot of health conditions to manage, but I want you to know that I\'m here to help you every step of the way. We\'ll work together to come up with a plan that addresses all of your concerns and helps you achieve optimal health.' },
      { id: 35, sender: '', message: 'THE DIALOG with PROVIDER/PATIENT COMPLETED, COMPLETE YOUR NOTES WITH DIAGNOSES, HISTORY OF EACH ISSUES AND A PLAN' },
      // Add more dialog messages as needed
      // Add more dialog messages as needed
    ];

    this.state = {
      displayedMessages: [], // Track displayed messages
      currentMessageIndex: 0, // Track the index of the current message
      isPaused: false, // Track pause/resume state
      likes: {}, // Track the number of likes for each message
      likesCount: {},
      displayButtonClicked: false,
    };

    this.timeouts = []; // Track timeouts for cleanup

    this.firebaseConfig = {
      // Add your Firebase configuration here
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(this.firebaseConfig);
    }

    this.firebaseDatabase = firebase.database();
  }

  componentDidMount() {


    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
    }); 
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   }
    // });

    this.loadLikesFromFirebase();

    this.displayMessagesInSuccession();
    this.loadLikesFromFirebase();
  }

  componentWillUnmount() {
    this.clearTimeouts();

      // Unsubscribe from Firebase listener
   this.unsubscribeFromAuth();
   this.firebaseDatabase.ref('likes').off();
  }
  
  
  handleDisplayMessages = () => {

    
    this.setState({
      displayedMessages: this.dialog,
      displayButtonClicked: true,
    });

    this.setState({ isPaused: true });
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.timeouts = [];
  };

  displayMessagesInSuccession() {
    const { currentMessageIndex, isPaused } = this.state;
  
    if (currentMessageIndex >= this.dialog.length || isPaused) {
      return;
    }
    if (currentMessageIndex >= this.dialog.length - 1) {
      return; // Do not perform any action if the last message is displayed
    }
    const message = this.dialog[currentMessageIndex];
    const timeout = setTimeout(() => {
      this.setState(
        (prevState) => ({
          displayedMessages: prevState.displayedMessages.concat(message),
          currentMessageIndex: prevState.currentMessageIndex + 1,
        }),
        () => {
          this.displayMessagesInSuccession();
        }
      );
    }, 4000); // Delay each message by 1 second
  
    this.timeouts.push(timeout);
  }
  
  clearTimeouts() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
  }
  handlePauseResume = () => {
    const { currentMessageIndex } = this.state;
    if (currentMessageIndex >= this.dialog.length - 1) {
      return; // Do not perform any action if the last message is displayed
    }
    this.setState(
      (prevState) => ({
        isPaused: !prevState.isPaused,
      }),
      () => {
        if (!this.state.isPaused) {
          this.displayMessagesInSuccession();
        }
      }
    );
  };
  handleLike = (messageId) => {
    const { likes, user } = this.state;
  
    
    this.setState(
      (prevState) => ({
        likes: {
          ...prevState.likes,
          [messageId]: {
            ...(prevState.likes[messageId] ? prevState.likes[messageId] : {}),
            [user.uid]: {
              displayName: user.displayName,
              liked: true,
            },
          },
        },
      }),
      () => {
        // Update the Firebase database with the updated likes
        const { likes } = this.state;
        const messageRef = firebase.database().ref(`messages/${messageId}/likes`);
        messageRef.set(likes[messageId]);
      }
    );
  };
  
  loadLikesFromFirebase() {
    this.firebaseDatabase.ref('likes').on('value', (snapshot) => {
      const likes = snapshot.val() || {};
      this.setState({ likes });
  
      // Calculate the likes count
      const likesCount = {};
      Object.values(likes).forEach((like) => {
        Object.keys(like).forEach((key) => {
          likesCount[key] = (likesCount[key] || 0) + 1;
        });
      });
      this.setState({ likesCount });
    });
  }
  saveLikesToFirebase() {
    const { likes } = this.state;
    this.firebaseDatabase.ref('likes').set(likes);
  }

 

  renderChatBubbles() {
    const { displayedMessages, likes, user } = this.state;
  
    return displayedMessages.map((message) => {
      const bubbleClass = message.sender === 'Provider' ? 'bubble Provider' : 'bubble Patient';
      const isLikedByUser = likes[message.id] && likes[message.id][user.uid]; // Check if the current user liked the message
  
      return (
        <div key={message.id} className={bubbleClass} style={{ color: 'white', position: 'relative' }}>
          {message.message}
          <br /><br />
          <div style={{ position: 'absolute', bottom: '2px', left: '8px' }}>
 
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={isLikedByUser ? 'red' : (message.sender === 'Provider' ? 'white' : 'grey')}
      viewBox="0 0 16 16"
      style={{
        display: 'block',
        margin: '0 auto',
        left: '15px',
        top: '5px'
      }}
      onClick={() => this.handleLike(message.id)}
    >
      <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm6 3.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
    </svg>
    {/* <span style={{ color:'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{this.state.likesCount[message.id] || 0}</span> */}

</div>
          <div style={{ position: 'absolute', bottom: '2px', right: '2px' }}>
            <button
              style={{
                width: '60px',
                height: '20px',
                fontFamily: 'sans-serif',
                fontSize: '9px',
                color: message.sender === 'Provider' ? 'white' : 'grey',
                backgroundColor: message.sender === 'Provider' ? 'grey' : 'lightblue',
                border: '0',
                borderRadius: '5px',
                boxShadow: '0 2px rgb(40, 127, 184)',
                cursor: 'pointer',
                outline: '0',
              }}
              onClick={() => this.handleLike(message.id)}
            >
              ({message.sender})
            </button>
          </div>
        </div>
      );
    });
  }
  


  
  render() {
    const { isPaused, displayButtonClicked } = this.state;
  
    return (
      <div className="chat-container" style={{ background: '#252d4a', marginTop: '10px' }}   >
      {this.renderChatBubbles()}
      <div>
        {!displayButtonClicked && ( // Only show the resume button if display button is not clicked
          <button
            className="badge badge-info"
            style={{
              backgroundColor: 'light green',
              color: 'white',
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              marginRight: '10px'
            }}
            onClick={this.handlePauseResume}
          >
            {isPaused ? 'Resume' : 'Pause'}
          </button>
        )}
    
        <button
          className="badge badge-info"
          style={{
            backgroundColor: 'light grey',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
          }}
          onClick={this.handleDisplayMessages}
        >
          Display Messages
        </button>
      </div>
    </div>
    );
  }
}

export default ChatBubble;
