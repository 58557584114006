import React, { Component } from 'react';
// import styled from "styled-components";
import '../App.css';
import unsplash from '../images/unsplash.jpg'
import '../Modal.css';
import firebase, { auth, provider } from '../firebase.js';
import Modal from 'react-modal';
import swal from 'sweetalert';



class Health extends Component {

  constructor(props) {
    super();
    this.state = {
      currentItem: '',
      currentItem1: '',
      allergies: '',
      pmh: '',
      psh: '',
      famhx: '',
      sochx: '',
      phq: '',
      functional: '',
      cogn: '',
      diet: '',
      excerise: '',
      Alcohol: '',
      smoking: '',
      vaccinations: '',
      medications: '',
      health: '',
      username: '',
      Left007: [],
      Left008: [],
      Left009: [],
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      },
      modelText01: '',
      modalIsOpen: false,
      modalIsOpen0: false,
      modalIsOpen1: false,
      displayButtonClicked: true,
      displayButtonClicked2: true,
      displayButtonClicked3: true,


    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit0 = this.handleSubmit0.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);

    // this.handleInputChange01 = this.handleInputChange01.bind(this);

  }
  // handleInputChange01(event) {
  //   this.setState({ modelText01: event.target.value });
  // }

  handleDisplayMessages = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked: true
      });
    }
  }
  

  
  };

  handleDisplayMessages2 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked2) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked2: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked2: true
      });
    }
  }
  
  };

  handleDisplayMessages3 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked3) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked3: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked3: true
      });
    }
  }
  
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    const Left1Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left009`);

    const item1 = {
      health: this.state.health,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }

    Left1Ref.push(item1);
    this.setState({

      health: '',


      username: ''
    });
  }
  }


  handleSubmit0(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
    const LeftRef = firebase.database().ref(`users/${this.state.user.displayName}/Left007`);

    const item = {
      vaccinations: this.state.vaccinations,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }

    LeftRef.push(item);

    this.setState({
      vaccinations: '',
      username: ''

    });
  }
  }

  handleSubmit1(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
    const Left0Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left008`);
    const item0 = {
      medications: this.state.medications,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }


    Left0Ref.push(item0);

    this.setState({

      medications: '',


      username: ''

    });
  }
  }

  // componentWillMount(props, state){
  //   this.setState({ showSmall: false });

  // }


  _isMounted = false;


  componentDidMount(userId) {

    this._isMounted = true;

    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (this._isMounted && user) {
        this.setState({ user });
      }
    }); 
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   }
    // });


    const LeftRef = firebase.database().ref('users');
    LeftRef.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left007) {
          let Left007 = user.Left007;
          for (let itemId in Left007) {
            let item = Left007[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              vaccinations: item.vaccinations,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left007: newState
      });
    });

    const Left0Ref = firebase.database().ref('users');
    Left0Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left008) {
          let Left008 = user.Left008;
          for (let itemId in Left008) {
            let item = Left008[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              medications: item.medications,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left008: newState
      });
    });

    const Left1Ref = firebase.database().ref('users');
    Left1Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left009) {
          let Left009 = user.Left009;
          for (let itemId in Left009) {
            let item = Left009[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              health: item.health,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left009: newState
      });
    });

  }

  
  handleEditChange(event, itemId) {
    const { value } = event.target;
    this.setState((prevState) => ({
      Left007: prevState.Left007.map((item) => {
        if (item.itemId === itemId) {
          return { ...item, vaccinations: value };
        }
        return item;
      }),
    }));
  }
  
  startEditItem(itemId) {
    this.setState({ editingItemId: itemId });
  }
  
  submitEditItem(itemId) {
    const updatedVaccinations = this.state.Left007.find((item) => item.itemId === itemId).vaccinations;
    const itemRef = firebase.database().ref(`users/${this.state.user.displayName}/Left007/${itemId}`);
    
    itemRef.update({ vaccinations: updatedVaccinations })
      .then(() => {
        console.log('Item updated successfully.');
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.error('Error updating item:', error);
      });
  }

  
  handleEditChange2 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left008: prevState.Left008.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            medications: updatedValue,
          };
        }
        return item;
      }),
    }));
  };
  
  startEditItem2 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };
  
  submitEditItem2 = (itemId) => {
    const updatedItem = this.state.Left008.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left008/${itemId}`);
    
    userRef.update({ medications: updatedItem.medications })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };

  handleEditChange3 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left009: prevState.Left009.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            health: updatedValue,
          };
        }
        return item;
      }),
    }));
  };
  
  startEditItem3 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };
  
  submitEditItem3 = (itemId) => {
    const updatedItem = this.state.Left009.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left009/${itemId}`);
  
    userRef.update({ health: updatedItem.health })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
    // Unsubscribe from Firebase listener
    firebase.database().ref('users').off();
    this.unsubscribeFromAuth();
  }



  removeItem(userId, itemId) {
    const itemRef = firebase.database().ref(`users/${userId}/Left007/${itemId}`);
    itemRef.remove();

    const itemRef0 = firebase.database().ref(`users/${userId}/Left008/${itemId}`);
    itemRef0.remove();

    const itemRef1 = firebase.database().ref(`users/${userId}/Left009/${itemId}`);
    itemRef1.remove();
  }


  render() {
    const { focussed, value, error, label } = this.state;
    const { id, type, locked } = this.props;
    const { displayButtonClicked } = this.state;
    const { displayButtonClicked2 } = this.state;
    const { displayButtonClicked3 } = this.state;
    const styles = {

      slide: {
        fontSize: "12px",
        maxWidth: "100%",
        overflow: "hidden",
        display: "block",
        lineHeight: "1",
        border: "none",
        borderBottom: "none",
        outline: "none",
      },

      svg: {
        display: 'block',
        margin: '0 auto',
        left: '10px',
        top: '5px',
        bottom: '50px',
      },

      radio: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px', // Adjust the top padding to make the items thinner
        height: '30px', // Adjust the height of each item
        width: '100%', // Ensure the items have the same width
      },

      lists: {
        height: '20px',
        width: '300px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '12px',
        paddingLeft: '5px',
        color: 'grey',
        // width: '98%',
        marginLeft: '10px',
        marginTop: '20px', // Set the font color to grey
        whiteSpace: 'pre-wrap', // Allow wrapping at whitespace
        overflowWrap: 'break-word',
      },

      userinfo: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '10px',
        position: 'relative',
        border: '1px solid',
        width: '98%', 
        borderRadius: '25px',
        overflow: 'hidden' // Add this line
      },
      userInfoLine: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '1px',
        background: 'linear-gradient(to right, purple, green)'
      },

      Input: { 
        fontSize: '12px', 
      paddingLeft: '14px', 
      cursor: 'pointer' }

    };


    const Leftside2 = () => {
      return (
        <div className="Container">

          <div className="CommunityCard">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <section className='add-item2'>


              </section>

            </div>


          </div>
        </div>
      );


    };

    return (


      <div>

        {this.state.user ?
           <div >
           <div className="UserInfo" style= {styles.userinfo}>
                <div style={styles.userInfoLine}></div> 
                <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                <label htmlFor="vaccinations" onClick={() => swal("Vaccinations", "...Update history of Vaccination including type of Vaccination and dates received including COVID (Dates, Boosters), HPV, Zoster, Flu, Pneumococcus Vaccines")}>Vaccinations:</label>
                <input
                  id="vaccinations"
                  type="text"
                  placeholder="Enter your Vaccinations here"
                  name="vaccinations"
                  onChange={this.handleChange}
                  value={this.state.vaccinations}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      this.handleSubmit0();
                    }
                  }}
                  style={styles.lists}
                />
                  <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              </form>

              
                  {/* <button onClick={() => this.setState({ modalIsOpen: true })}>Open Modal</button> */}

                  {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen} onRequestClose={() => this.setState({ modalIsOpen: false })}>
                    <h2 key="modalTitle">Vaccinations</h2>
                    <div className="search-results">
                      {this.state.Left007
                        .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                        .map((item) => (
                          <React.Fragment key={item.itemId}>
                            <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
                              <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })}
                                onClick={() => this.removeItem(item.userId, item.itemId)} />

                              <div style={{ fontSize: "12px", paddingLeft: "140" }}>
                                {item.vaccinations}
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                  </Modal> */}

{displayButtonClicked && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left007
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.vaccinations}
                              onChange={(event) => this.handleEditChange(event, item.itemId)}
                              onKeyPress={event => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem(item.itemId);
                                }
                              }}
                              style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem(item.itemId)}
                            >
                              {item.vaccinations}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}
              
</div>
           
           <div className="UserInfo" style= {styles.userinfo}>
                     <div style={styles.userInfoLine}></div> 
              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
              
                  <label htmlFor="medications" onClick={() => swal("Medication", "...Determine the Medications and document the Prescription doses, frequency, and directions for taking medication including any Over the counter medications")}>Medications:</label>
                  <input
                    id="medications"
                    type="text"
                    placeholder="Enter your Medications here"
                    name="medications"
                    onChange={this.handleChange}
                    value={this.state.medications}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleSubmit1();
                      }
                    }}
                    style={styles.lists}
                  />
                  <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages2}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
                </form>

                {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen0} onRequestClose={() => this.setState({ modalIsOpen0: false })}>
                  <h2 key="modalTitle">Medications</h2>
                  <div className="search-results">
                    {this.state.Left008

                      .map((item) => (
                        <React.Fragment key={item.itemId}>
                          <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
                            <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })}
                              onClick={() => this.removeItem(item.userId, item.itemId)} />

                            <div style={{ fontSize: "12px", paddingLeft: "140" }}>
                              {item.medications}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                </Modal> */}
    {displayButtonClicked2 && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left008
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.medications}
                              onChange={(event) => this.handleEditChange2(event, item.itemId)}
                              onKeyPress={event => {
                               if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem2(item.itemId);
                                }
                              }}
                              style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem2(item.itemId)}
                            >
                              {item.medications}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}

</div>
           
           <div className="UserInfo" style= {styles.userinfo}>
                     <div style={styles.userInfoLine}></div> 
              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                  <label htmlFor="health" onClick={() => swal("Pap Smear/Well Woman's Exam (Age over 20, Annually): Document the date and result of the patient's last Pap smear. If the patient is due for a Pap smear, refer them to an OB/GYN for further evaluation or perform the exam in-office during the well woman's visit. Breast Cancer Screening (Age over 40, Mammogram): Record the dates and results of the patient's mammogram screenings.If necessary, order a mammogram and document the date of the order. If there are any concerns, refer the patient for further evaluation. Colonoscopy (Men and Women over 50): Document if a colonoscopy has been ordered and refer the patient to a GI specialist for the procedure. If the patient is due for a colonoscopy, order the referral and record the date of completion and the results once the procedure is done. Depression PHQ-9 Scores and Alcohol Use Scores: Evaluate the patient's depression using the PHQ-9 questionnaire and document the scores. Assess the patient's alcohol use and document the relevant scores. Other Routine Annuals: Conduct screenings for sexually transmitted diseases. Perform Prostate-Specific Antigen (PSA) screening for men. Evaluate the patient's HbA1c levels for diabetes screening. Review and document any EKG results. Address health maintenance issues related to cardiac and diabetic conditions. Update the patient's Health Care Proxy (Emergency contact) annually. Record the dates of the patient's last eye and dental exams, and document any referrals ordered if necessary. By adhering to these guidelines, we ensure a comprehensive health maintenance assessment for the patient during their annual or new patient visit")}>Health Annuals:</label>
                  <input
                    id="health"
                    type="text"
                    placeholder="Enter your Health Annuals assessment here"
                    name="health"
                    onChange={this.handleChange}
                    value={this.state.health}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleSubmit();
                      }
                    }}
                    style={styles.lists}
                  />
                    <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages3}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                  </div>
                </form>
{/*                              
        <Modal className="modalContainer" isOpen={this.state.modalIsOpen1} onRequestClose={() => this.setState({ modalIsOpen1: false })}>
  <h2 key="modalTitle">Health Annuals</h2>
  <div className="search-results">
    {this.state.Left009
    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
    .map((item) => (
      <React.Fragment key={item.itemId}>
        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })} 
  onClick={() => this.removeItem(item.userId, item.itemId)} />
       
        <div style={{ fontSize: "12px", paddingLeft: "140" }}>
        {item.health}
        </div>
        </div>
        </React.Fragment>
    ))}
  </div>
</Modal> */}
{displayButtonClicked3 && (
  <div>
    {this.state.Left009
      .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
      // .filter(item => item.selectedProp === this.props.selectedProp)
      .map((item) => (
        <React.Fragment key={item.itemId}>
          <div
            key={item.itemId}
            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
          >
            <input
              type="radio"
              name="radio-button"
              checked={false}
              onChange={() => this.setState({ selectedRadio: item.itemId })}
              onClick={() => this.removeItem(item.userId, item.itemId)}
            />
            {this.state.editingItemId === item.itemId ? (
              <textarea
                type="text"
                value={item.health}
                onChange={(event) => this.handleEditChange3(event, item.itemId)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    this.submitEditItem3(item.itemId);
                  }
                }}
                style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
              />
            ) : (
              <div
                style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                onClick={() => this.startEditItem3(item.itemId)}
              >
                {item.health}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
  </div>
)}

</div>


<a>

</a>








</div>
          :
          <p>You must be logged in to see the potluck list and submit to it.</p>
        }

      </div>
      // </WebView>  </view>



    );


  }
}
export default Health;

