import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { store } from './store';
import "./assets/plugins/nucleo/css/nucleo.css";
import "./assets/scss/argon-dashboard-react.scss";
import Modal from 'react-modal';
// import Typebot from '@typebot.io/js';
// import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2/dist/web.js'
Modal.setAppElement(document.body);
import 'es-module-shims'; 
import { GlobalContextProvider } from "./components/GlobalContext";

// const App2 = () => {
//   useEffect(() => {
//     Typebot.initBubble({
//       typebot: "lead-scoring-3ogjz0k",
//       theme: { chatWindow: { backgroundColor: "#fff" } },
//     });
//   }, []);

//   return null;
// };

const typebotInitScript = document.createElement("script");
typebotInitScript.type = "module";
typebotInitScript.innerHTML = `import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2/dist/web.js'

Typebot.initBubble({ typebot: "lead-scoring-3ogjz0k" });
`;
document.body.append(typebotInitScript);

ReactDOM.render(
  <Provider store={store}>
   <GlobalContextProvider value="what">
        <App />
      </GlobalContextProvider>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();

