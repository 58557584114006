
// import 'firebase/compat/firestore';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/storage'; // Import the storage module
// // Add Firebase storage
// import 'firebase/compat/storage';
import 'firebase/compat/database';

const config = {
    apiKey: "AIzaSyD8lJA9t_S32GJMjTD8Xr3QBk9NvuVyMDg",
    authDomain: "medreact-cf09d.firebaseapp.com",
    databaseURL: "https://medreact-cf09d.firebaseio.com",
    projectId: "medreact-cf09d",
    storageBucket: "medreact-cf09d.appspot.com",
    messagingSenderId: "622421712499",
    appId: "1:622421712499:web:13f2814a314cdeea681406",
    Clientid: "622421712499-lu5csgu477ee5n5hf68f62pnmohr4ruu.apps.googleusercontent.com"
};





firebase.initializeApp(config);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const storage = firebase.storage(); // Initialize the storage module
export const database = firebase.database(); 
export default firebase;