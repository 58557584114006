import React, { Component } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { connect } from 'react-redux';
import { ActionTypes } from './constants/actionTypes';

import firebase, { auth, provider } from './firebase.js';
import GoogleButton from "react-google-button";
import Button from "@material-ui/core/Button";
import Login from './Login.js';
import Register from "./Register";

import Hero from "./components2/Hero";
import Slider from "./components2/Slider";
import travel_02 from "./components2/pexels4.jpeg";

import travel_05 from "./components2/2023-04-01_17h11_05 00018.jpg";

import { Route, Link } from 'react-router-dom';

import Leftside001 from './Leftside001.js';


import "./App.css";

import SideMenu from "./components/SideMenu";
import MobileTabBar from "./components/MobileTabBar";

import logo from "./img/fhir-logo-long.png";
import icon from "./img/icon.png";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";

import { Layout, Avatar, Popconfirm, Radio, Tooltip, Modal, message } from "antd";
import GlobalContextConsumer from "./components/GlobalContext";

import { GlobalContext, GlobalContextProvider } from "./components/GlobalContext";
import ObjectiveTab from './components2/ObjectiveTab.js';
import Assessment from '../src/SOAP/Assessment';
import Assessment2 from '../src/SOAP/Assessment2';
import Assessment3 from '../src/SOAP/Assessment3';


const { Header, Sider, Footer } = Layout;





const mapStateToProps = state => { return { ...state.quiz } };
const mapDispatchToProps = dispatch => ({
  onQuizLoad: payload => dispatch({ type: ActionTypes.QuizLoad, payload }),
  onPagerUpdate: payload => dispatch({ type: ActionTypes.PagerUpdate, payload })
});

class DesktopMenu extends React.Component {
  state = {
    collapsed: false
  };

  updateCollapsed = collapsed => {
    this.setState({
      collapsed: collapsed
    });
  };

  componentDidMount() {
    message.config({
      top: 80
    });
  }

  render() {
    const ToBuy2 = () => (
      <>
     
          
      
        <Slider
       imageSrc={travel_05}
          title={<span style={{ color: 'white' }}></span>}
          subtitle={<span style={{ color: 'white' }}></span>}
         
        />
       
      </>
    );


    return (
      <Sider
        collapsible
        onCollapse={this.updateCollapsed}
        breakpoint="lg"
        width="230"
        style={{
          boxShadow: "7px 0px 20px -10px rgba(0,0,0,0.35)",
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0
        }}
      >
        <Link to="/">
          <div
            className="logo"
            style={{
              background: `url("${this.state.collapsed ? icon : logo}") no-repeat`
            }}
          ></div>
        </Link>
        <SideMenu></SideMenu>
        <ToBuy2></ToBuy2>
        <div style={{ flexGrow: 1 }}></div>
      </Sider>
    );
  }
}


class DesktopMenu2 extends React.Component {
  componentDidMount() {
    message.config({
      top: 80
    });
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <MobileTabBar />
      </div>
    );
  }
}



const ToBuy = () => (
  <>
   
    <Hero
   
    />
  </>
);

const routes = [
  {
    path: "/",
    exact: true,
    title: () => "Home",
    main: () => <ToBuy  />
  },
  
];



class App extends Component {
  state = {
    quizId: 'data/javascript.json',
    currentItem: '',
    currentItem1: '',
    username: '',
    items: [],
    user: null,
    selectedProp: '', 
  };

  constructor(props) {
    super(props);
    this.superheroElement = React.createRef();
    this.childRef = React.createRef();
  }

  static contextType = GlobalContext;

  handleViewChange = value => {
    const { setViewInCard } = this.context;
    console.log(setViewInCard, value, this.context);
    setViewInCard(value == "card");
  };

  confirmLogout = () => {
    Modal.success({
      title: "You have successfully logged out",
      content: "You will be logged out of a real system, but not in this demo prototype."
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  login = async () => {
    auth.signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        this.setState({
          user
        });
      });
  }

  setUser = (user) => {
    this.login();
  }

  login2 = () => {
    this.login3(this.setUser);
  }

  logout = () => {
    auth.signOut()
      .then(() => {
        this.setState({
          user: null
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const itemsRef = firebase.database().ref('items');
    const item = {
      hint: this.state.currentItem1,
      title: this.state.currentItem,
      user: this.state.user.displayName || this.state.user.email
    }
    itemsRef.push(item);
    this.setState({
      currentItem: '',
      currentItem1: '',
      username: ''
    });
  }

  showAlert = () => {
    alert('Hello World');
  }

  pager = {
    index: 0,
    size: 1,
    count: 1
  }

  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
    });

    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          user: items[item].user
        });
      }
      this.setState({
        items: newState
      });
    });
  }

  componentWillUnmount() {
    firebase.database().ref('users').off();
    this.unsubscribeFromAuth();
  }

  load(quizId) {
    let url = quizId || this.props.quizId;
    fetch(`../${url}`).then(res => res.json()).then(res => {
      let quiz = res;
      quiz.questions.forEach(q => {
        q.options.forEach(o => o.selected = false);
      });
      quiz.config = Object.assign(this.props.quiz.config || {}, quiz.config);
      this.pager.count = quiz.questions.length / this.pager.size;
      this.props.onQuizLoad(quiz);
      this.props.onPagerUpdate(this.pager);
    });
  }

  onChange = (e) => {
    const pressed = e.target.value;
    const selectedProp = e.target.value;
    this.setState({ selectedProp });
  }

  render() {
    const { selectedProp } = this.state;


    console.log(this.props);
    console.log(window.location.hostname);
   
    
    const basename =
      window.location.hostname == "nexmdnote.com"
        ? "Dashboard/#/"
        : "";


    const NavBar = () => (
      
      <div className="navbar"   > 
       
        <div className="wrapper">
      {this.state.user ? (
        <Button
          className="loginBtn"
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          onClick={this.logout}
        >
          Logout
        </Button>
      ) : (
        <div >
           <h3 style={{ color: 'white' }}>Scribe </h3>
        </div>
      )}
    </div>
         {this.state.user ? (
                  <div className="col-6 text-right">
                    <label className="mr-2" style={{ color: 'white' }}>
                      Select Chart:
                    </label>
                    <select
  style={{
    boxShadow: 'none',
    color: 'black',
    backgroundColor: '#ffffff', 
  }}
  onChange={this.onChange}
  value={selectedProp}
>
  <option value="" style={{ color: 'black' }}>Select an option</option>
  <option value="new patient" style={{ color: 'black' }}>New Patient</option>
  <option value="annual" style={{ color: 'black' }}>Annual</option>
  <option value="follow up" style={{ color: 'black' }}>Follow Up</option>
</select>
                  </div>
                ) : (
                  <p></p>
                )}
            <div className='question-section'>
              <div className="row">
                             
              </div> <div className="links">
        
        </div>
            </div>
       
      </div>
    );

    const ToBuy = () => (
      <>
     
       
      
        <Slider
          imageSrc={travel_02}
          title={<span style={{ color: 'white' }}>Be Bold. Go to Start</span>}
          subtitle={<span style={{ color: 'white' }}>"Our platform offers a variety of model examples of primary care patient visits!"</span>}
         
        />
       
      </>
    );

   

    const ToDialogue = () => (
      <>
     
        <div  >
          <div  >
          <div style={{ background: '#252d4a' }} > <Route
      path="/visit"
      render={() =>
        <>
        {this.state.selectedProp === 'new patient' ? (
          <Assessment style={{ background: '#252d4a' }} />
        ) : this.state.selectedProp === 'annual' ? (
          <Assessment2 style={{ background: '#252d4a' }} />
        ) : this.state.selectedProp === 'follow up' ? (
          <Assessment3 style={{ background: '#252d4a' }} />
        ) : (
          <p>No assessment available for the selected chart.</p>
        )}
      </>
      }
    />  </div>
          </div>
         
        </div>
        
      </>
    );

    const Start = () => (
      <>
     
        <div className='app' >
          <div className='container' >
          {this.state.user ? <br /> : <Login />}
          <div className="col-6">
                  <header>
                    <div className="wrapper">
                      {this.state.user ? (
                        <div></div>
                      ) : (
                        <div style={{ textAlign: 'center', margin: '50px' }}>
                          <GoogleButton onClick={this.login}>Log In</GoogleButton>
                        </div>
                      )}
                    </div>
                 
                  </header>
                </div>
           
            {this.state.user ? <Leftside001 selectedProp={selectedProp} /> : <br />}
            
            <br />
          </div>
         
        </div>
        
      </>
    );


    
    return (
   
      <div style={{ background: '#252d4a' }} >
      <Router basename={basename} >    <NavBar/> 
        <Layout style={{ minHeight: 100 + "vh", background: '#252d4a' }}>
         
            <GlobalContextConsumer>
            {value => {
              if (!value.isMobile) {
                return <DesktopMenu></DesktopMenu>;
              } else {
                return <DesktopMenu2></DesktopMenu2>;
              }
            }}
          </GlobalContextConsumer> 
          <div style={{ background: '#252d4a' }}>
      <Route exact path="/" component={ToBuy} />
      <Route path="/start" component={Start} />
      <Route path="/register" component={Register} />
    
    </div>
    
    <div className='app'  >
             <div className='container' >
       <Route
      path="/start"
      render={() =>
        this.state.user ? (
          <div className="wrapper"  >  <ObjectiveTab selectedProp={selectedProp} /></div>
         
        ) : (
          <br />
        )
      }
    />  </div>
     </div>
 
    

<div style={{ background: '#252d4a' }}>
  <Route
    path="/visit"
    render={() => (
      <>
        {this.state.selectedProp === 'new patient' ? (
          <Assessment style={{ background: '#252d4a' }} />
        ) : this.state.selectedProp === 'annual' ? (
          <Assessment2 style={{ background: '#252d4a' }} />
        ) : this.state.selectedProp === 'follow up' ? (
          <Assessment3 style={{ background: '#252d4a' }} />
        ) : (
          <p>No assessment available for the selected chart.</p>
        )}
      </>
    )}
  />
</div>





    
          <Layout style={{ background: '#252d4a' }}  >

                   <RouterContent></RouterContent>  
          </Layout>
        </Layout>
      </Router>
    
      <div >

   
        </div>
     
      <div></div>
    </div>
  );
  
  }
}

const RouterContent = () => {
  let location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="page" timeout={300}>
        <Switch location={location}>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={
                <div className="container">
                  <div className="page">
                    <route.main />
                    <FhirFooter></FhirFooter>
                  </div>
                </div>
              }
            />
          ))}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const FhirFooter = () => {
  return (
    <Footer style={{ textAlign: "center" }} className="footer">
      eScribe Dashboard ©2018 Created by <a href="www.site.nexmdnote.com">minimednote</a>
      <div style={{ opacity: 0.5 }}>
        Using{" "}
        <a href="https://www.hl7.org/fhir/" style={{ color: "black" }}>
          HL7 FHIR
        </a>{" "}
                <div>
          <a
            onClick={() => {
              window.$globalPatients = null;
              localStorage.removeItem("patients");
              message.success("Local data cache cleared");
            }}
          >
            Click here to clear local FHIR data cache
          </a>
        </div>
      </div>
    </Footer>
  );
};

export async function login3(callback) {
  auth.signInWithPopup(provider)
    .then((result) => {
      const user = result.user;
      this.login();
      this.setState({
        user
      });
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
