// import styled from "styled-components";
import React, { Component } from 'react';

import unsplash from '../images/unsplash.jpg'
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import Body from './Body';
// import Leftside from '../Leftside';
import Subjective from '../SOAP/Subjective';
import SubjectiveTab from './SubjectiveTab.js';
import Draggable from 'react-draggable'
// import Header001 from './Header001';



class ObjectiveTab extends Component {

  constructor(props) {
    super();
    this.state = {
      currentItem: '',
      currentItem1: '',
      currentItem3: '',
      currentItem5:'',
      selectedProp: props.selectedProp, 

      username: '',
      items: [],
      HPI: [],
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      }
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.refreshItems = this.refreshItems.bind(this);
  }

// Add the updateItemOrder function to your component
updateItemOrder = (oldIndex, newIndex) => {
  const items = [...this.state.items];
  const item = items.splice(oldIndex, 1)[0];
  items.splice(newIndex, 0, item);
  this.setState({ items });
}
component = (node) => {
  if (node) {
    const handleDrag = (event) => {
      const currentItemIndex = this.state.items.findIndex((item) => item.id === node.getAttribute('data-id'));
      const dropIndex = this.state.items.findIndex((item) => node.compareDocumentPosition(item.id) & Node.DOCUMENT_POSITION_FOLLOWING);
      if (currentItemIndex !== dropIndex) {
        this.updateItemOrder(currentItemIndex, dropIndex);
      }
    };
    node.addEventListener('dragend', handleDrag);
  }
};
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }


  handleSubmit(e) {
    e.preventDefault();
    const itemsRef = firebase.database().ref('items');
    const { currentItem, currentItem1, currentItem5, selectedProp, user } = this.state; // Destructure state variables
    
    // Create a new item object with all necessary properties
    const newItem = {
      title: currentItem,
      hint: currentItem1,
      item5: currentItem5,
      selectedProp: selectedProp, // Include selectedProp
      user: user.displayName || user.email
    };
  
    // Push the new item to Firebase
    itemsRef.push(newItem)
      .then(() => {
        // Clear the input fields after successful submission
        this.setState({
          currentItem: '',
          currentItem1: '',
          currentItem5: '',
        // Optionally clear the selectedProp as well
          username: ''
        });
      })
      .catch(error => {
        // Handle errors if any
        console.error('Error adding item to Firebase:', error);
      });
  }
  

  handleSubmit1(e) {
    // e.preventDefault();
   
    const { currentItem3, currentItemId, user, selectedProp  } = this.state;
    const itemsRef2 = firebase.database().ref('HPI');
  
    if (currentItemId) {
      // Delete the previous HPI entry
      itemsRef2.child(currentItemId).remove()
        .then(() => {
          // Create the updated item
          const item = {
            HPI: currentItem3,
            selectedProp: this.state.selectedProp, 
            user: user.displayName || user.email
          };
  
          // Add the updated item to the Firebase database
          itemsRef2.push(item)
            .then(() => {
              this.setState({
                currentItem3: '',
                currentItemId: null,
               
                username: ''
              });
            })
            .catch(error => {
              // Handle error
              console.log('Error creating updated item:', error);
            });
        })
        .catch(error => {
          // Handle error
          console.log('Error deleting previous item:', error);
        });
    } else {
      // Create a new item
      const item = {
        HPI: currentItem3,
        selectedProp: this.state.selectedProp, 
        user: user.displayName || user.email
      };
  
      itemsRef2.push(item)
        .then(() => {
          this.setState({
            currentItem3: '',
            
            username: ''
          });
        })
        .catch(error => {
          // Handle error
          console.log('Error creating item:', error);
        });
    }
  }
  
  

  componentDidUpdate(prevProps, prevState) {
    // Check if items were added to Firebase database
    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          hint: items[item].hint,
          user: items[item].user,
          item5: items[item].item5,
          selectedProp: items[item].selectedProp
        });
      }
      if (prevState.items.length !== newState.length) {
        this.setState({
          items: newState
        });
      }
    });

    if (this.props.selectedProp !== prevProps.selectedProp) {
      this.setState({ selectedProp: this.props.selectedProp }, () => {
        // Reload data when selectedProp changes
        this.loadData();
      });
    }
    
  }


  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
    }); 
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   } 
    // });

    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          hint: items[item].hint,
          user: items[item].user,
          item5: items[item].item5,
          selectedProp: items[item].selectedProp

        });

      }
      this.setState({
        items: newState
      });
    });

  
      const itemsRef2 = firebase.database().ref('HPI');
      itemsRef2.on('value', (snapshot) => {
        const HPI = snapshot.val();
        const newState = [];
        for (let item in HPI) {
          newState.push({
            id: item,
            HPI: HPI[item].HPI,
            user: HPI[item].user,
            selectedProp: HPI[item].selectedProp
          });
        }
        this.setState({
          HPI: newState
        });
      });
    
      this.loadData();

  }

  componentWillUnmount() {
    // Unsubscribe from Firebase listener
    firebase.database().ref('items').off();
    firebase.database().ref('HPI').off();
    
    this.unsubscribeFromAuth();
 
  }



  loadData() {
    // Load data based on the selectedProp
    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        if (items[item].selectedProp === this.state.selectedProp) {
          newState.push({
            id: item,
            title: items[item].title,
            hint: items[item].hint,
            user: items[item].user,
            item5: items[item].item5,
            selectedProp: items[item].selectedProp
          });
        }
      }
      this.setState({
        items: newState
      });
    });

    const itemsRef2 = firebase.database().ref('HPI');
    itemsRef2.on('value', (snapshot) => {
      const HPI = snapshot.val();
      const newState = [];
      for (let item in HPI) {
        if (HPI[item].selectedProp === this.state.selectedProp) {
          newState.push({
            id: item,
            HPI: HPI[item].HPI,
            user: HPI[item].user,
            selectedProp: HPI[item].selectedProp
          });
        }
      }
      this.setState({
        HPI: newState
      });
    });
  }
  removeItem(itemId) {
    const itemRef = firebase.database().ref(`/items/${itemId}`);
    itemRef.remove();

    const itemRef2 = firebase.database().ref(`/HPI/${itemId}`);
    itemRef2.remove();

//added to instantly transform the list with instant update to the state
    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          hint: items[item].hint,
          user: items[item].user,
          item5: items[item].item5,
          selectedProp: items[item].selectedProp
        });

      }
      this.setState({
        items: newState
      });
    });
  }

  render() {
    const userItems = this.state.items.filter(
      (item) =>
        (item.user === this.state.user.displayName || item.user === this.state.user.email) &&
        item.selectedProp === this.state.selectedProp
    );
    const { focussed, value, error, label } = this.state;
    const { id, type, locked } = this.props;
    const { selectedProp } = this.state;
    const styles = {

      slide: {
        fontSize: "12px",
        maxWidth: "100%",
        overflow: "hidden",
        display: "block",
        lineHeight: "1",
        border: "none",
        borderBottom: "none",
        outline: "none",
      },

      svg: {
        display: 'block',
        margin: '0 auto',
        left: '10px',
        top: '5px',
        bottom: '50px',
      },

      radio: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px', // Adjust the top padding to make the items thinner
        height: '20px', // Adjust the height of each item
        width: '100%', // Ensure the items have the same width

      },

      lists: {
        height: '20px',
        width: '300px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '12px',
        paddingLeft: '5px',
        color: 'grey',
        // width: '98%',
        marginLeft: '10px',
        marginTop: '20px', // Set the font color to grey
        whiteSpace: 'pre-wrap', // Allow wrapping at whitespace
        overflowWrap: 'break-word',
      },


      userinfo: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '10px',
        position: 'relative',
        border: '1px solid',
        width: '98%', 
        borderRadius: '25px',
        overflow: 'hidden' // Add this line
      },
      userInfoLine: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '1px',
        background: 'linear-gradient(to right, purple, green)'
      },
      Input: {
        fontSize: '12px',
        paddingLeft: '14px',
        cursor: 'pointer'
      }

    };


    const Leftside2 = () => {
      return (
        <div className="Container">

          <div className="CommunityCard">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <section className='add-item2'>


              </section>

            </div>


          </div>
        </div>
      );


    };


    return (
      <div   style={{ width: '400px',fontSize: '11px', justify: 'center', align: 'center', marginTop:'0px' }} >

      
        {this.state.user ?
          <section  >
            {/* <div className="wrapper"> */}
            
              {/* <Subjective /> */}
              <div className="UserInfo" >
  
    
  {/* <div className="AddPhotoText">e-chart</div>





  <img src="/images/item-icon.svg" alt="" />
<h1> History of Presenting Illness</h1>
 */}


 
<div className="card_item" style={styles.userinfo}> 
         <div style={styles.userInfoLine}></div>
<div className="card_inner">

{/* {this.state.HPI.map(item => (
  <div key={item.id}>
    <textarea value={item.HPI} readOnly />
    {item.user === this.state.user.displayName || item.user === this.state.user.email ? (
      <button onClick={() => this.handleEditHPI(item.id)}>Edit</button>
    ) : null}
  </div>
))} */}

<form >
<textarea
style={{
color: 'black',
height: '250px',
width: '330px',
fontSize: '16px',
padding: '1px',
borderRadius: '5px',
paddingBottom: '110px',
paddingLeft: '1px',
border: 'none',
boxShadow: '0 0 5px rgba(0,0,0,0.3)',
resize: 'none' // <-- Add this line to disable resizing
}}
name="currentItem3"
placeholder="HPI"
onChange={this.handleChange}
value={this.state.currentItem3}
onKeyPress={event => {
  if (event.key === 'Enter') {
    event.preventDefault();
    this.handleSubmit1();
  }
}}
/>
</form >
  
</div>

</div> 
 
<div>
  Add HPI and complete with summary
  <br />
  of medical conditions, name of patient,
  <br />
  background details and acute complaints
  <br />
  including any other purposes for the visit. Press Enter to save
</div>

      {/* <button onClick={compareText} >Compare</button> */}
{/* <p>Similarity Score: {similarityScore}%</p> */}
{/* <p>Similarity Score: {parseFloat(similarityScore).toFixed(0)}%</p> */}


</div>
    <SubjectiveTab  selectedProp={selectedProp}/>       
   


{this.state.HPI.map((item) => {
  if (
    (item.user === this.state.user.displayName || item.user === this.state.user.email) &&
    item.selectedProp === this.state.selectedProp // Check selectedProp
  ) {
    return (
      <div key={item.id} style={{ border: '1px solid grey', padding: '10px', marginBottom: '10px', borderRadius:'25px' }} >
        <div className='UserInfo'>
          <h3 style={{ fontSize: '11px', color: 'white', marginBottom: '10px'}}>{item.HPI}</h3>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <button
                className="badge badge-info"
                style={{
                  backgroundColor: 'grey',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  border: 'none',
                  marginRight: '10px',
                  display: 'flex'
                }}
                onClick={() => this.removeItem(item.id)}
              >
                Remove Item
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
})}

{/* {this.state.items.map((item) => {
  if (
    (item.user === this.state.user.displayName || item.user === this.state.user.email) &&
    item.selectedProp === this.state.selectedProp // Check selectedProp
  ) {
    return (
      <div key={item.id} style={{ border: '1px solid grey', padding: '10px', marginBottom: '10px', borderRadius:'25px' }} >
        <div className='UserInfo'>
          <h3 style={{ fontSize: '11px', color: 'white', marginBottom: '10px'}}>{item.item5}</h3>
          <h3 style={{ fontSize: '11px', color: 'white', marginBottom: '10px'}}>{item.title}</h3>
          <div>
            <div style={{ fontSize: '11px', color: 'white', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {item.hint.replace('.', '')}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <button
                className="badge badge-info"
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  border: 'none',
                  marginRight: '10px',
                  display: 'flex'
                }}
                onClick={() => this.removeItem(item.id)}
              >
                Remove Item
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
})} */}
{/* {this.state.items.map((item, index) => {
  if (
    (item.user === this.state.user.displayName || item.user === this.state.user.email) &&
    item.selectedProp === this.state.selectedProp // Check selectedProp
  ) {
    return (
      <Draggable key={item.id} handle=".handle" >
        <div style={{ border: '1px solid grey', padding: '10px', marginBottom: '10px', borderRadius:'25px' }} >
          <div className='handle'>
            <h3 style={{ fontSize: '11px', color: 'white', marginBottom: '10px'}}>{index}</h3> 
          </div>
          <div className='UserInfo'>
            <h3 style={{ fontSize: '11px', color: 'white', marginBottom: '10px'}}>{item.title}</h3>
            <div>
              <div style={{ fontSize: '11px', color: 'white', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.hint.replace('.', '')}
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  } else {
    return null;
  }
})} */}

<div>
      {userItems.map((item, index) => (
        <Draggable key={item.id} handle=".handle">
          <div style={{ position: 'relative', border: '1px solid grey', padding: '10px', marginBottom: '10px', borderRadius: '25px' }}>
            <button
              onClick={() => this.removeItem(item.id)}
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                background: 'transparent',
                border: 'none',
                color: '#ADD8E6',
                fontSize: '11px',
                cursor: 'pointer'
              }}
            >
              X
            </button>
            <div className="handle">
              <h3 style={{ fontSize: '11px', color: 'white', marginBottom: '10px' }}>{index + 1}</h3>
            </div>
            <div className="UserInfo">
              <h3 style={{ fontSize: '11px', color: 'white', marginBottom: '10px' }}>{item.title}</h3>
              <div>
                <div
                  style={{
                    fontSize: '11px',
                    color: 'white',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {item.hint.replace('.', '')}
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      ))}
    </div>

          </section>
          :
          <p></p>
        }

      </div>

      // </WebView>  </view>



    );


  }
}

// export async function refreshItems(callback) {
//   const itemsRef = firebase.database().ref('items');
//   itemsRef.on('value', (snapshot) => {
//     let items = snapshot.val();
//     let newState = [];
//     for (let item in items) {
//       newState.push({
//         id: item,
//         title: items[item].title,
//         hint: items[item].hint,
//         user: items[item].user

//       });

//     }
//     this.setState({
//       items: newState
//     });
//   });
// }
 

export default ObjectiveTab;


