import React, { Component } from 'react';
// import styled from "styled-components";
import '../App.css';
import unsplash from '../images/unsplash.jpg'
import '../Modal.css';
import firebase, { auth, provider } from '../firebase.js';
import Modal from 'react-modal';
import swal from 'sweetalert';



class Diet extends Component {

  constructor(props) {
    super();
    this.state = {
      currentItem: '',
      currentItem1: '',
      allergies: '',
      pmh: '',
      psh: '',
      famhx: '',
      sochx: '',
      phq: '',
      functional: '',
      cogn: '',
      diet: '',
      excerise: '',
      Alcohol: '',
      smoking: '',
      vaccinations: '',
      medications: '',
      username: '',
      Left001: [],
      Left002: [],
      Left003: [],
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      },
      modelText01: '',
      modalIsOpen: false,
      modalIsOpen0: false,
      modalIsOpen1: false,
      displayButtonClicked: true,
      displayButtonClicked2: true,
      displayButtonClicked3: true,


    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit0 = this.handleSubmit0.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);

    // this.handleInputChange01 = this.handleInputChange01.bind(this);

  }
  // handleInputChange01(event) {
  //   this.setState({ modelText01: event.target.value });
  // }

  handleDisplayMessages = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked: true
      });
    }
  }
  

  
  };

  handleDisplayMessages2 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked2) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked2: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked2: true
      });
    }
  }
  
  };

  handleDisplayMessages3 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked3) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked3: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked3: true
      });
    }
  }
  
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }


  handleSubmit(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    const Left1Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left003`);


    const item1 = {
      diet: this.state.diet,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }

    Left1Ref.push(item1);
    this.setState({

      diet: '',


      username: ''
    });
  }
  }


  handleSubmit0(e) {

    const LeftRef = firebase.database().ref(`users/${this.state.user.displayName}/Left001`);
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    const item = {
      functional: this.state.functional,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }

    LeftRef.push(item);

    this.setState({
      functional: '',
      username: ''

    });
  }

}

  handleSubmit1(e) {
if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    const Left0Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left002`);

    const item0 = {
      cogn: this.state.cogn,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }


    Left0Ref.push(item0);

    this.setState({

      cogn: '',


      username: ''

    });
  }
  }


  // componentWillMount(props, state) {
  //   this.setState({ showSmall: false });

  // }

  _isMounted = false;


  componentDidMount(userId) {

    this._isMounted = true;

    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (this._isMounted && user) {
        this.setState({ user });
      }
    }); 
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   }
    // });



    const LeftRef = firebase.database().ref('users');
    LeftRef.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left001) {
          let Left001 = user.Left001;
          for (let itemId in Left001) {
            let item = Left001[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              functional: item.functional,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left001: newState
      });
    });

    const Left0Ref = firebase.database().ref('users');
    Left0Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left002) {
          let Left002 = user.Left002;
          for (let itemId in Left002) {
            let item = Left002[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              cogn: item.cogn,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left002: newState
      });
    });

    const Left1Ref = firebase.database().ref('users');
    Left1Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left003) {
          let Left003 = user.Left003;
          for (let itemId in Left003) {
            let item = Left003[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              diet: item.diet,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left003: newState
      });
    });

  }

  handleEditChange(event, itemId) {
    const { value } = event.target;
    this.setState((prevState) => ({
      Left001: prevState.Left001.map((item) => {
        if (item.itemId === itemId) {
          return { ...item, functional: value };
        }
        return item;
      }),
    }));
  }
  
  startEditItem(itemId) {
    this.setState({ editingItemId: itemId });
  }
  
  submitEditItem(itemId) {
    const updatedFunctional = this.state.Left001.find((item) => item.itemId === itemId).functional;
    const itemRef = firebase.database().ref(`users/${this.state.user.displayName}/Left001/${itemId}`);
    
    itemRef.update({ functional: updatedFunctional })
      .then(() => {
        console.log('Item updated successfully.');
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.error('Error updating item:', error);
      });
  }

  
  handleEditChange2 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left002: prevState.Left002.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            cogn: updatedValue,
          };
        }
        return item;
      }),
    }));
  };
  
  startEditItem2 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };
  
  submitEditItem2 = (itemId) => {
    const updatedItem = this.state.Left002.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left002/${itemId}`);
    
    userRef.update({ cogn: updatedItem.cogn })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };

  handleEditChange3 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left003: prevState.Left003.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            diet: updatedValue,
          };
        }
        return item;
      }),
    }));
  };
  
  startEditItem3 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };
  
  submitEditItem3 = (itemId) => {
    const updatedItem = this.state.Left003.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left003/${itemId}`);
  
    userRef.update({ diet: updatedItem.diet })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
    // Unsubscribe from Firebase listener
    firebase.database().ref('users').off();

    this.unsubscribeFromAuth();
  }


  removeItem(userId, itemId) {
    const itemRef = firebase.database().ref(`users/${userId}/Left001/${itemId}`);
    itemRef.remove();

    const itemRef0 = firebase.database().ref(`users/${userId}/Left002/${itemId}`);
    itemRef0.remove();

    const itemRef1 = firebase.database().ref(`users/${userId}/Left003/${itemId}`);
    itemRef1.remove();
  }

  render() {
    const { focussed, value, error, label } = this.state;
    const { id, type, locked } = this.props;
    const { displayButtonClicked } = this.state;
    const { displayButtonClicked2 } = this.state;
    const { displayButtonClicked3 } = this.state;
    const styles = {

      slide: {
        fontSize: "12px",
        maxWidth: "100%",
        overflow: "hidden",
        display: "block",
        lineHeight: "1",
        border: "none",
        borderBottom: "none",
        outline: "none",
      },

      svg: {
        display: 'block',
        margin: '0 auto',
        left: '10px',
        top: '5px',
        bottom: '50px',
      },

      radio: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px', // Adjust the top padding to make the items thinner
        height: '30px', // Adjust the height of each item
        width: '100%', // Ensure the items have the same width
      },

      lists: {
        height: '20px',
        width: '300px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '12px',
        paddingLeft: '5px',
        color: 'grey',
        // width: '98%',
        marginLeft: '10px',
        marginTop: '20px', // Set the font color to grey
        whiteSpace: 'pre-wrap', // Allow wrapping at whitespace
        overflowWrap: 'break-word',
      },
      

      userinfo: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '10px',
        position: 'relative',
        border: '1px solid',
        width: '98%', 
        borderRadius: '25px',
        overflow: 'hidden' // Add this line
      },
      userInfoLine: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '1px',
        background: 'linear-gradient(to right, purple, green)'
      },

      Input: { 
        fontSize: '12px', 
      paddingLeft: '14px', 
      cursor: 'pointer' }

    };


    const Leftside2 = () => {
      return (
        <div className="Container">

          <div className="CommunityCard">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <section className='add-item2'>


              </section>

            </div>


          </div>
        </div>
      );


    };

    return (


      <div>

        {this.state.user ?
         <div >
         <div className="UserInfo" style= {styles.userinfo}>
              <div style={styles.userInfoLine}></div>
              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                <label htmlFor="functional" onClick={() => swal("Functional", "... e.g. Patient that is able perform normal daily activities, with out limitations - e.g Fully Functional with All ADLS")}>Functional ADLS:</label>
              
                <input
                  id="functional"
                  type="text"
                  placeholder="Enter your Functional assessment here"
                  name="functional"
                  onChange={this.handleChange}
                  value={this.state.functional}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      this.handleSubmit0();
                    }
                  }}
                  style={styles.lists}
                />

<div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>

              </form>


       
                  {/* <button onClick={() => this.setState({ modalIsOpen: true })}>Open Modal</button> */}

{/* 
                  <Modal className="modalContainer" isOpen={this.state.modalIsOpen} onRequestClose={() => this.setState({ modalIsOpen: false })}>
                    <h2 key="modalTitle">Functional</h2>
                    <div className="search-results">
                      {this.state.Left001
                        .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                        .map(item => (
                          <React.Fragment key={item.itemId}>
                            <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
                              <input
                                type="radio"
                                name="radio-button"
                                checked={false}
                                onChange={() => this.setState({ selectedRadio: item.id })}
                                onClick={() => this.removeItem(item.userId, item.itemId)}
                              />

                              <div style={{ fontSize: "12px", paddingLeft: "140" }}>
                                {item.functional}
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                  </Modal> */}

{displayButtonClicked && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left001
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.functional}
                              onChange={(event) => this.handleEditChange(event, item.itemId)}
                              onKeyPress={event => {
                                    if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem(item.itemId);
                                }
                              }}
                               style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem(item.itemId)}
                            >
                              {item.functional}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}



                </div>


                <div className="UserInfo" style= {styles.userinfo}>
          <div style={styles.userInfoLine}></div>

              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                  <label htmlFor="cogn" onClick={() => swal("Cognition", "...Determine if the patient is alert, orientated x 4 - Person, Place, Time and Situation. Patient is fully alert and aware of the situation been presented during the visit")}>Cognition:</label>
                  
                  <input
                    id="cogn"
                    type="text"
                    placeholder="Enter your Alert, Awake and Oriented assesment"
                    name="cogn"
                    onChange={this.handleChange}
                    value={this.state.cogn}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleSubmit1();
                      }
                    }}
                    style={styles.lists}
                  />
                     <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages2}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
                </form>

                {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen0} onRequestClose={() => this.setState({ modalIsOpen0: false })}>
                  <h2 key="modalTitle">Cognition</h2>
                  <div className="search-results">
                    {this.state.Left002
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
                          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)} />

                          <div style={{ fontSize: "12px", paddingLeft: "140" }}>
                            {item.cogn}
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </Modal> */}
             {displayButtonClicked2 && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left002
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.cogn}
                              onChange={(event) => this.handleEditChange2(event, item.itemId)}
                              onKeyPress={event => {
                                    if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem2(item.itemId);
                                }
                              }}
                               style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem2(item.itemId)}
                            >
                              {item.cogn}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}

</div>
           
           <div className="UserInfo" style= {styles.userinfo}>
                     <div style={styles.userInfoLine}></div> 
              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                  
                  <label htmlFor="diet"  onClick={() => swal("Diet", "...Update Diet information, or Recommended Low Salt, Low Fat, Low Carbs and Low Sugar - especially for patients with chronic metabolic conditions or weight loss")}>Diet:</label>
                  <input
                    id="diet"
                    type="text"
                    placeholder="Enter your Diet here"
                    name="diet"
                    onChange={this.handleChange}
                    value={this.state.diet}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleSubmit();
                      }
                    }}
                    style={styles.lists}
                  />
                  <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages3}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                  </div>
                </form>

{/* 
                <Modal className="modalContainer" isOpen={this.state.modalIsOpen1} onRequestClose={() => this.setState({ modalIsOpen1: false })}>
                  <h2 key="modalTitle">Diet</h2>
                  <div className="search-results">
                    {this.state.Left003
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
                          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)} />

                          <div style={{ fontSize: "12px", paddingLeft: "140" }}>
                            {item.diet}
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </Modal> */}
{displayButtonClicked3 && (
  <div>
    {this.state.Left003
      .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
      // .filter(item => item.selectedProp === this.props.selectedProp)
      .map((item) => (
        <React.Fragment key={item.itemId}>
          <div
            key={item.itemId}
            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
          >
            <input
              type="radio"
              name="radio-button"
              checked={false}
              onChange={() => this.setState({ selectedRadio: item.itemId })}
              onClick={() => this.removeItem(item.userId, item.itemId)}
            />
            {this.state.editingItemId === item.itemId ? (
              <textarea
                type="text"
                value={item.diet}
                onChange={(event) => this.handleEditChange3(event, item.itemId)}
                onKeyPress={event => {
                      if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    this.submitEditItem3(item.itemId);
                  }
                }}
                 style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
              />
            ) : (
              <div
                style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                onClick={() => this.startEditItem3(item.itemId)}
              >
                {item.diet}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
  </div>
)}
         </div>


              <a>

              </a>








            </div>
     
          :
          <p>You must be logged in to see the potluck list and submit to it.</p>
        }

      </div>
      // </WebView>  </view>



    );


  }
}
export default Diet;

