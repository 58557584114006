import React, { Component } from 'react';
// import styled from "styled-components";
import '../App.css';
import unsplash from '../images/unsplash.jpg'
import '../Modal.css';
import firebase, { auth, provider } from '../firebase.js';
import Modal from 'react-modal';
import swal from 'sweetalert';



class Psh extends Component {

  constructor(props) {
    super();
    this.state = {
      currentItem: '',
      currentItem1: '',
      famhx:'',
      sochx:'',
      phq:'',
      username: '',
      Left01: [],
      Left02: [],
      Left03: [],
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
    },
     modelText01: '',
     modalIsOpen: false,
     modalIsOpen0: false,
     modalIsOpen1: false,
     displayButtonClicked: true,
      displayButtonClicked2: true,
      displayButtonClicked3: true,
  
    
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit0 = this.handleSubmit0.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    // this.handleInputChange01 = this.handleInputChange01.bind(this);
   
  }
  // handleInputChange01(event) {
  //   this.setState({ modelText01: event.target.value });
  // }

  handleDisplayMessages = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked: true
      });
    }
  }
  

  
  };

  handleDisplayMessages2 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked2) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked2: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked2: true
      });
    }
  }
  
  };

  handleDisplayMessages3 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked3) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked3: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked3: true
      });
    }
  }
  
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  
  handleSubmit(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
    const Left1Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left03`);
      const item1 = {
     phq:this.state.phq,
     user: this.state.user.displayName || this.state.user.email,
     selectedProp: this.props.selectedProp
   }
  
    Left1Ref.push(item1);
    this.setState({
      
        phq:'',
        

      username: ''
    });
  }
  }

  
  handleSubmit0(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
    const LeftRef = firebase.database().ref(`users/${this.state.user.displayName}/Left01`);
      
    const item = {
     famhx: this.state.famhx,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }
  
    LeftRef.push(item);
 
    this.setState({
        famhx:'',
        username: ''
     
    });
  }
  }

  handleSubmit1(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
    const Left0Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left02`);
 
       const item0 = {
        sochx:this.state.sochx,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }
    

    Left0Ref.push(item0);
   
    this.setState({
      
        sochx:'',
           

      username: ''
     
    });
  }
  }

  // componentWillMount(props, state){
  //   this.mounted = true; 
  
  //     // if(this.mounted){}
  
     
      
  // }


  _isMounted = false;

  componentDidUpdate(prevProps, prevState) {


    // Check if items were added to Firebase database
    const LeftRef = firebase.database().ref('users');
    LeftRef.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left01) {
          let Left01 = user.Left01;
          for (let itemId in Left01) {
            let item = Left01[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              famhx: item.famhx,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      // Check if state needs to be updated
      if (JSON.stringify(this.state.users) !== JSON.stringify(newState)) {
        this.setState({
          users: newState
        });
      }
    });
  }
  
  componentDidMount(userId) {

    this._isMounted = true;

    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (this._isMounted && user) {
        this.setState({ user });
      }
    }); 

    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   } 
    // });
    const LeftRef = firebase.database().ref('users');
    LeftRef.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left01) {
          let Left01 = user.Left01;
          for (let itemId in Left01) {
            let item = Left01[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              famhx: item.famhx,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left01: newState
      });
    });

       
   
    const Left0Ref = firebase.database().ref('users');
    Left0Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left02) {
          let Left02 = user.Left02;
          for (let itemId in Left02) {
            let item = Left02[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              sochx: item.sochx,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left02: newState
      });
    });



      
    const Left1Ref = firebase.database().ref('users');
    Left1Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left03) {
          let Left03 = user.Left03;
          for (let itemId in Left03) {
            let item = Left03[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              phq: item.phq,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left03: newState
      });
    });

  }

  
  handleEditChange(event, itemId) {
    const { value } = event.target;
    this.setState((prevState) => ({
      Left01: prevState.Left01.map((item) => {
        if (item.itemId === itemId) {
          return { ...item, famhx: value };
        }
        return item;
      }),
    }));
  }
  
  startEditItem(itemId) {
    this.setState({ editingItemId: itemId });
  }
  
  submitEditItem(itemId) {
    const updatedFamilyhistory = this.state.Left01.find((item) => item.itemId === itemId).famhx;
    const itemRef = firebase.database().ref(`users/${this.state.user.displayName}/Left01/${itemId}`);
    
    itemRef.update({ famhx: updatedFamilyhistory })
      .then(() => {
        console.log('Item updated successfully.');
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.error('Error updating item:', error);
      });
  }

  
  handleEditChange2 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left02: prevState.Left02.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            sochx: updatedValue,
          };
        }
        return item;
      }),
    }));
  };
  
  startEditItem2 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };
  
  submitEditItem2 = (itemId) => {
    const updatedItem = this.state.Left02.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left02/${itemId}`);
    
    userRef.update({ sochx: updatedItem.sochx })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };

  handleEditChange3 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left03: prevState.Left03.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            phq: updatedValue,
          };
        }
        return item;
      }),
    }));
  };
  
  startEditItem3 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };
  
  submitEditItem3 = (itemId) => {
    const updatedItem = this.state.Left03.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left03/${itemId}`);
  
    userRef.update({ phq: updatedItem.phq })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };
  
 
  componentWillUnmount() {
    this._isMounted = false;
    // Unsubscribe from Firebase listener
    firebase.database().ref('users').off();
    this.unsubscribeFromAuth();
  }



  removeItem(userId, itemId) {
    const itemRef = firebase.database().ref(`users/${userId}/Left01/${itemId}`);
    itemRef.remove();

    const itemRef0 = firebase.database().ref(`users/${userId}/Left02/${itemId}`);
    itemRef0.remove();

    const itemRef1 = firebase.database().ref(`users/${userId}/Left03/${itemId}`);
    itemRef1.remove();
  }
  
  render() {
    const { focussed, value, error, label } = this.state;
    const { id, type, locked } = this.props;
    const { displayButtonClicked } = this.state;
    const { displayButtonClicked2 } = this.state;
    const { displayButtonClicked3 } = this.state;
    const styles = {

      slide: {
        fontSize: "12px",
        maxWidth: "100%",
        overflow: "hidden",
        display: "block",
        lineHeight: "1",
        border: "none",
        borderBottom: "none",
        outline: "none",
      },

      svg: {
        display: 'block',
        margin: '0 auto',
        left: '10px',
        top: '5px',
        bottom: '50px',
      },

      radio: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px', // Adjust the top padding to make the items thinner
        height: '30px', // Adjust the height of each item
        width: '100%', // Ensure the items have the same width
      },

      lists: {
        height: '20px',
        width: '300px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '12px',
        paddingLeft: '5px',
        color: 'grey',
        // width: '98%',
        marginLeft: '10px',
        marginTop: '20px', // Set the font color to grey
        whiteSpace: 'pre-wrap', // Allow wrapping at whitespace
        overflowWrap: 'break-word',
      },

      
      userinfo: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '10px',
        position: 'relative',
        border: '1px solid',
        width: '98%', 
        borderRadius: '25px',
        overflow: 'hidden' // Add this line
      },
      userInfoLine: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '1px',
        background: 'linear-gradient(to right, purple, green)'
      },

      Input: { 
        fontSize: '12px', 
      paddingLeft: '14px', 
      cursor: 'pointer' }

    };


    const Leftside2 = () => {
      return (
        <div className="Container">
               
              <div className="CommunityCard">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <section className='add-item2'> 
              
                          
              </section>
              
              </div>
              
                
              </div>
            </div>
      );
    
    
      };
      
   return (

    
  <div>

  
    {this.state.user ?
    <div >
     <div className="UserInfo" style= {styles.userinfo}>
          <div style={styles.userInfoLine}></div>
             <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
      <label htmlFor="famhx" onClick={() => swal("Family History", "...Determine Family History, Mother (Living or Deceased), Age and Any Medical conditions, Father (Living or Deceased), Age and Any Medical conditions, History to including any significant medication conditions in family members including chronic conditions (HTN, DM, HLD, Osteoarthritis) or cancers")}>Family History:</label>
      <input
        id="famhx"
        type="text"
        placeholder="Enter your Family history here"
        name="famhx"
        onChange={this.handleChange}
        value={this.state.famhx} 
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit0();
          }
        }} 
        style={styles.lists}
      />
      <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
    </form>
           
  
   
      {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen} onRequestClose={() => this.setState({ modalIsOpen: false })}>
  <h2 key="modalTitle">Family History</h2>
  <div className="search-results">
    {this.state.Left01.map((item) => (
      <React.Fragment key={item.itemId}>
        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })} 
  onClick={() => this.removeItem(item.userId, item.itemId)} />
       
        <div style={{ fontSize: "12px", paddingLeft: "140" }}>
          {item.famhx}
        </div>
        </div>
      </React.Fragment>
    ))}
  </div>
</Modal> */}
       {displayButtonClicked && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left01
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.famhx}
                              onChange={(event) => this.handleEditChange(event, item.itemId)}
                              onKeyPress={event => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem(item.itemId);
                                }
                              }}
                               style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem(item.itemId)}
                            >
                              {item.famhx}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}




      
      </div> 
    
      <div className="UserInfo" style= {styles.userinfo}>
          <div style={styles.userInfoLine}></div>
              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
             <label htmlFor="sochx" onClick={() => swal("Social History", "...Document basic social history including Marital status - Married, Single or Divorced, # of Children (with Gravida Para - G P for Patient OB/GYN history), Yes or No - Alcohol use, Tobacco or smoking and Drugs use, Occupation, Religion, Place of Birth")}>Social History:</label>
      <input
        id="sochx"
        type="text"
        placeholder="Enter your Social History here"
        name="sochx"
        onChange={this.handleChange}
        value={this.state.sochx} 
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit1();
          }
        }} 
        style={styles.lists}
      />
       <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages2}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                  </div>
    </form>

    
        {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen0} onRequestClose={() => this.setState({ modalIsOpen0: false })}>
  <h2 key="modalTitle">Social History</h2>
  <div className="search-results">
    {this.state.Left02.map((item) => (
      <React.Fragment key={item.itemId}>
        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })} 
  onClick={() => this.removeItem(item.userId, item.itemId)} />
       
        <div style={{ fontSize: "12px", paddingLeft: "140" }}>
        {item.sochx}
        </div>
        </div>
      </React.Fragment>
    ))}
  </div>
</Modal> */}

             
{displayButtonClicked2 && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left02
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value= {item.sochx}
                              onChange={(event) => this.handleEditChange2(event, item.itemId)}
                              onKeyPress={event => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem2(item.itemId);
                                }
                              }}
                               style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem2(item.itemId)}
                            >
                               {item.sochx}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}




</div>
           
           <div className="UserInfo" style= {styles.userinfo}>
                     <div style={styles.userInfoLine}></div> 
    <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                  
             <label htmlFor="phq" onClick={() => swal("Complete PHQ-9 Questionaire", "...Document the PHQ-9 scores")}>PHQ-9:</label>
             <input
        id="phq9"
        type="text"
        placeholder="Enter your PHQ-9 here"
        name="phq"
        onChange={this.handleChange}
        value={this.state.phq} 
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit();
          }
        }} 
        style={styles.lists}
      />
      <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages3}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                  </div>
    </form>

        {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen1} onRequestClose={() => this.setState({ modalIsOpen1: false })}>
  <h2 key="modalTitle">PHQ-9</h2>
  <div className="search-results">
    {this.state.Left03.map((item) => (
      <React.Fragment key={item.itemId}>
        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })} 
  onClick={() => this.removeItem(item.userId, item.itemId)} />
       
        <div style={{ fontSize: "12px", paddingLeft: "140" }}>
        {item.phq}
        </div>
        </div>
      </React.Fragment>
    ))}
  </div>
</Modal>     */}
{displayButtonClicked3 && (
  <div>
    {this.state.Left03
      .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
      // .filter(item => item.selectedProp === this.props.selectedProp)
      .map((item) => (
        <React.Fragment key={item.itemId}>
          <div
            key={item.itemId}
            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
          >
            <input
              type="radio"
              name="radio-button"
              checked={false}
              onChange={() => this.setState({ selectedRadio: item.itemId })}
              onClick={() => this.removeItem(item.userId, item.itemId)}
            />
            {this.state.editingItemId === item.itemId ? (
              <textarea
                type="text"
                value={item.phq}
                onChange={(event) => this.handleEditChange3(event, item.itemId)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    this.submitEditItem3(item.itemId);
                  }
                }}
                 style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
              />
            ) : (
              <div
                style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                onClick={() => this.startEditItem3(item.itemId)}
              >
                {item.phq}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
  </div>
)}
  </div>


    <a>

</a>








</div>

: 
<p>You must be logged in to see the potluck list and submit to it.</p>
}

 </div>
    // </WebView>  </view>
     
      
      
      ); 

   
  }
}
export default Psh;

