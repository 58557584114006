import React from 'react';
import './ChatBubble.css'; // CSS file for styling chat bubbles
import firebase from 'firebase/compat/app';
import 'firebase/database';
import  { auth, provider } from '../firebase.js';
import YouTubeShortVideoNP from './YouTubeShortVideoNP';
import Modal from 'react-modal';

import '../App.css';
class ChatBubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      },
      modalIsOpen: true,
      
    }






    this.dialog = [
      // Pre-recorded dialog data
      { id: 1, sender: 'Provider', message: 'Good morning Miss Henty Todd, how are you feeling today?' },
      { id: 2, sender: 'Patient', message: 'Hi doc, I\'m feeling a bit overwhelmed, to be honest. I have a lot going on health-wise and it\'s been tough to keep track of everything.' },
      { id: 3, sender: 'Provider', message: 'I\'m sorry to hear that. Let\'s take a look at your chart and make sure to complete everything you need to help you for your visit today' },
      { id: 4, sender: 'Patient', message: 'So I\'m here for my regular office visit and I wanted to discuss a few health concerns with you.' },
      // { id: 5, sender: 'user', message: '' },
      { id: 6, sender: 'Provider', message: 'Sure, I\'m here to help. What seems to be the problem?' },
      { id: 7, sender: 'Patient', message: 'So I\'m here for my regular office visit and I wanted to discuss a few health concerns with you.' },
      { id: 8, sender: 'Provider', message: 'Of course, I\'m here to help. Please go ahead and let me know what\'s been bothering you.' },
      { id: 9, sender: 'Patient', message: 'I have a history of prediabetes, hypertension, and migraines. I\'ve been trying to manage them, but I wanted to get your opinion and guidance.' },
      { id: 10, sender: 'Provider', message: 'I see. It\'s important to address these conditions proactively. How have you been managing your prediabetes and hypertension so far?' },
      { id: 11, sender: 'Patient', message: 'I\'ve been watching my diet and trying to exercise regularly. I\'ve also been taking medication for my hypertension as prescribed.' },
      { id: 12, sender: 'Provider', message: 'That\'s good to hear. It\'s crucial to maintain a healthy lifestyle and adhere to medication for effective management. Have you experienced any recent symptoms or concerns related to these conditions?' },
      { id: 13, sender: 'Patient', message: 'No, not recently. However, I wanted to discuss the possibility of starting medication for my migraines. They have been affecting my daily life and becoming more frequent.' },
      { id: 14, sender: 'Provider', message: 'I understand how migraines can be debilitating. We can explore different treatment options for your migraines, including medication. Let\'s assess your symptoms and medical history in more detail.' },
      { id: 15, sender: 'Patient', message: 'Sure, I had my first migraine about five years ago, and they\'ve been occurring at least once or twice a month since then. Over-the-counter pain relievers don\'t provide sufficient relief.' },
      { id: 16, sender: 'Provider', message: 'I see. It\'s important to evaluate the triggers and patterns of your migraines to determine the most suitable treatment approach. We may consider preventive medications in addition to acute migraine treatments. I\'ll also order some tests to rule out any underlying causes.' },
      { id: 17, sender: 'Patient', message: 'Thank you, doc. I appreciate your thorough approach. Is there anything else I should be concerned about or any additional tests I should consider?' },
      { id: 18, sender: 'Provider', message: 'Given your medical history and previous pregnancies including c-sections, I would recommend as well scheduling your annual screening for breast cancer - Mammograms, well woman exam - Pap smears and Annual labs including STD screening and to ensure optimal health. We can also review your test results and discuss further treatment options for your migraines.' },
      { id: 19, sender: 'Patient', message: 'That sounds like a good plan. I\'ll schedule the follow-up visit. Thank you for your guidance, doc.' },
      { id: 20, sender: 'Provider', message: 'You\'re welcome, Miss Henty Todd. I\'m here to support you in managing your health. If you have any further questions or concerns, don\'t hesitate to reach out.' },
      { id: 21, sender: 'Provider', message: 'So, I see that you have seasonal allergies. How have they been lately?' },
      { id: 22, sender: 'Patient', message: 'They\'ve been bothering me quite a bit. I\'ve been experiencing a lot of sneezing, runny nose, and itchy eyes.' },
      { id: 23, sender: 'Provider', message: ' I\'m sorry to hear that. It\'s important that we manage your allergies properly to prevent them from interfering with your daily life. I\'ll prescribe some antihistamines and nasal sprays to help alleviate your symptoms.' },
      { id: 24, sender: 'Patient', message: 'Thank you, doc.' },
      { id: 25, sender: 'Provider', message: ' Next, I see that your blood pressure is elevated, but you haven\'t been diagnosed with hypertension. Have you been monitoring your blood pressure at home?' },
      { id: 26, sender: 'Patient', message: 'Yes, I have. It\'s been consistently high.' },
      { id: 27, sender: 'Provider', message: ' I see. We need to keep a close eye on your blood pressure to prevent it from causing any damage to your organs. I\'ll schedule some follow-up appointments to monitor your blood pressure, and I\'ll also prescribe medication to help lower it.' },
      { id: 28, sender: 'Patient', message: 'That sounds good, thank you.' },
      { id: 29, sender: 'Provider', message: 'Finally, you mentioned that you\'ve had two c-sections in the past (2001, 2003), and that your family history includes both parents being alive, aged 55 and 56. Is there anything else you want to share about your medical history?' },
      { id: 30, sender: 'Patient', message: 'Actually, I recently sprained my right foot and it\'s been causing a lot of pain.' },
      { id: 31, sender: 'Provider', message: 'Sure, I\'m here to help. What seems to be the problem?' },
      { id: 32, sender: 'Provider', message: 'I\'m sorry to hear that. Let\'s take a look at your foot and make sure there\'s no serious damage. I\'ll refer you to a specialist if necessary.' },
      { id: 33, sender: 'Patient', message: 'Thank you, doc.' },
      { id: 34, sender: 'Provider', message: 'Overall, you have a lot of health conditions to manage, but I want you to know that I\'m here to help you every step of the way. We\'ll work together to come up with a plan that addresses all of your concerns and helps you achieve optimal health.' },
      { id: 34, sender: 'Provider', message: 'Overall, you have a lot of health conditions to manage, but I want you to know that I\'m here to help you every step of the way. We\'ll work together to come up with a plan that addresses all of your concerns and helps you achieve optimal health.' },
      { id: 35, sender: '', message: 'THE DIALOG with PROVIDER/PATIENT COMPLETED, COMPLETE YOUR NOTES WITH DIAGNOSES, HISTORY OF EACH ISSUES AND A PLAN' },
      // Add more dialog messages as needed
      // Add more dialog messages as needed
    ];

    this.dialog.forEach(dialog => {
      dialog.message = dialog.message.replace(/\n/g, '<br>');
    });

    this.state = {
      displayedMessages: [], // Track displayed messages
      currentMessageIndex: 0, // Track the index of the current message
      isPaused: false, // Track pause/resume state
      likes: {}, // Track the number of likes for each message
      likesCount: {},
      displayButtonClicked: false,
    };

    this.timeouts = []; // Track timeouts for cleanup

    this.firebaseConfig = {
      // Add your Firebase configuration here
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(this.firebaseConfig);
    }

    this.firebaseDatabase = firebase.database();
  }

  componentDidMount() {


    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
    }); 
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   }
    // });

    this.loadLikesFromFirebase();

    this.displayMessagesInSuccession();
    this.loadLikesFromFirebase();
  }

  componentWillUnmount() {
    this.clearTimeouts();

      // Unsubscribe from Firebase listener
   this.unsubscribeFromAuth();
   this.firebaseDatabase.ref('likes').off();
  }
  
  
  handleDisplayMessages = () => {

    
    this.setState({
      displayedMessages: this.dialog,
      displayButtonClicked: true,
    });

    this.setState({ isPaused: true });
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.timeouts = [];
  };

  displayMessagesInSuccession() {
    const { currentMessageIndex, isPaused } = this.state;
  
    if (currentMessageIndex >= this.dialog.length || isPaused) {
      return;
    }
    if (currentMessageIndex >= this.dialog.length - 1) {
      return; // Do not perform any action if the last message is displayed
    }
    const message = this.dialog[currentMessageIndex];
    const timeout = setTimeout(() => {
      this.setState(
        (prevState) => ({
          displayedMessages: prevState.displayedMessages.concat(message),
          currentMessageIndex: prevState.currentMessageIndex + 1,
        }),
        () => {
          this.displayMessagesInSuccession();
        }
      );
    }, 4000); // Delay each message by 1 second
  
    this.timeouts.push(timeout);
  }
  
  clearTimeouts() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
  }
  handlePauseResume = () => {
    const { currentMessageIndex } = this.state;
    if (currentMessageIndex >= this.dialog.length - 1) {
      return; // Do not perform any action if the last message is displayed
    }
    this.setState(
      (prevState) => ({
        isPaused: !prevState.isPaused,
      }),
      () => {
        if (!this.state.isPaused) {
          this.displayMessagesInSuccession();
        }
      }
    );
  };
  handleLike = (messageId) => {
    const { likes, user } = this.state;
  
    
    this.setState(
      (prevState) => ({
        likes: {
          ...prevState.likes,
          [messageId]: {
            ...(prevState.likes[messageId] ? prevState.likes[messageId] : {}),
            [user.uid]: {
              displayName: user.displayName,
              liked: true,
            },
          },
        },
      }),
      () => {
        // Update the Firebase database with the updated likes
        const { likes } = this.state;
        const messageRef = firebase.database().ref(`messages/${messageId}/likes`);
        messageRef.set(likes[messageId]);
      }
    );
  };
  
  loadLikesFromFirebase() {
    this.firebaseDatabase.ref('likes').on('value', (snapshot) => {
      const likes = snapshot.val() || {};
      this.setState({ likes });
  
      // Calculate the likes count
      const likesCount = {};
      Object.values(likes).forEach((like) => {
        Object.keys(like).forEach((key) => {
          likesCount[key] = (likesCount[key] || 0) + 1;
        });
      });
      this.setState({ likesCount });
    });
  }
  saveLikesToFirebase() {
    const { likes } = this.state;
    this.firebaseDatabase.ref('likes').set(likes);
  }

  openModal = () => {
    const videoId = 'CBNaDltx5t8';
    this.setState({ modalIsOpen: true, videoId });
  };
  
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

 

 

  renderChatBubbles() {
    const { displayedMessages, likes, user } = this.state;
  
    return displayedMessages.map((message) => {
      const bubbleClass = message.sender === 'Provider' ? 'bubble Provider' : 'bubble Patient';
      const isLikedByUser = likes[message.id] && likes[message.id][user.uid]; // Check if the current user liked the message
  
      return (
        <div key={message.id} className={bubbleClass} style={{ color: 'white', position: 'relative' }}>
          {message.message}
          <br /><br />
          <div style={{ position: 'absolute', bottom: '2px', left: '8px' }}>
 
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={isLikedByUser ? 'red' : (message.sender === 'Provider' ? 'white' : 'grey')}
      viewBox="0 0 16 16"
      style={{
        display: 'block',
        margin: '0 auto',
        left: '15px',
        top: '5px'
      }}
      onClick={() => this.handleLike(message.id)}
    >
      <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm6 3.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
    </svg>
    {/* <span style={{ color:'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{this.state.likesCount[message.id] || 0}</span> */}

</div>
          <div style={{ position: 'absolute', bottom: '2px', right: '2px' }}>
            <button
              style={{
                width: '60px',
                height: '20px',
                fontFamily: 'sans-serif',
                fontSize: '9px',
                color: message.sender === 'Provider' ? 'white' : 'grey',
                backgroundColor: message.sender === 'Provider' ? 'grey' : 'lightblue',
                border: '0',
                borderRadius: '5px',
                boxShadow: '0 2px rgb(40, 127, 184)',
                cursor: 'pointer',
                outline: '0',
              }}
              onClick={() => this.handleLike(message.id)}
            >
              ({message.sender})
            </button>
          </div>
        </div>
      );
    });
  }
  


  
  render() {
    const { isPaused, displayButtonClicked } = this.state;
    const App = () => {
      const videoId = 'CBNaDltx5t8';}

    return (
      <div className="chat-container" style={{ background: '#252d4a', marginTop: '10px' }}   >
         <button
          className="badge badge-info"
          style={{
            backgroundColor: 'red',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            marginBottom: '15px',
            position: 'relative', bottom: '2px', left: '230px'
          }}
            onClick={this.openModal}
        >
          Youtube Play
        </button>
        <Modal
  
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
      >
        
        <h2 key="modalTitle">New Patient</h2>
        <div className="search-results">
          <YouTubeShortVideoNP videoId={this.state.videoId} />
        </div>
      </Modal>
      {this.renderChatBubbles()}
      <div>
        {!displayButtonClicked && ( // Only show the resume button if display button is not clicked
          <button
            className="badge badge-info"
            style={{
              backgroundColor: 'light green',
              color: 'white',
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              marginRight: '10px'
            }}
            onClick={this.handlePauseResume}
          >
            {isPaused ? 'Resume' : 'Pause'}
          </button>
        )}
    
        <button
          className="badge badge-info"
          style={{
            backgroundColor: 'light grey',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
          }}
          onClick={this.handleDisplayMessages}
        >
          Display Messages
        </button>
       
      </div>
    </div>
    );
  }
}

export default ChatBubble;
