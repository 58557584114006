// import styled from "styled-components";
import React, { Component, useState } from 'react';

import unsplash from '../images/unsplash.jpg'
import "../assets/plugins/nucleo/css/nucleo.css";
import "../assets/scss/argon-dashboard-react.scss";
import firebase, { auth, provider } from '../firebase.js';
import Body from './Body';
import Leftside001 from '../Leftside001';
import Modal from 'react-modal';
// import '../Modal.css';

// import Header001 from './Header001';
import OpenAI from 'openai';



const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

// Instantiate OpenAI with apiKey
const openai = new OpenAI({ apiKey, engine: "text-davinci-003", dangerouslyAllowBrowser: true  });


class SubjectiveTab extends Component {


  constructor(props) {
    super();
    this.state = {
      currentItem: '',
      currentItem1: '',
      currentItem5:'',
      username: '',
      selectedProp: props.selectedProp, 
      items: [],
      ze: [],
      searchQuery: '',
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      },
      modalIsOpen: false,
      loading: false,
      alternatingColors: 'orange',
      loadingText: 'Loading',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.openaiText = this.openaiText.bind(this);
  }

  async openaiText() {
    if (this.state.currentItem === "") {
      return null;
    } else {
      try {
        const response = await openai.chat.completions.create({
          model:"gpt-4o",
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: `(Write a complete sentence \n\n${this.state.currentItem} plan and assessment for this symptom, using a paragraph for the assessment based on the importance of the onset, progression,
           quality, severity, and trauma and 3 bullet-style plans to help in treatment, use a maximum of 500 words!)` },
          ],
          max_tokens: 60,
        });
  
        // The response.choices array might have multiple messages, so you may want to iterate through them
        const generatedText = response.choices.map(choice => choice.message.content).join('\n');
  
        console.log("OpenAI API Response:", response);
        console.log("Generated Text:", generatedText);
  
        this.setState({ currentItem1: generatedText });
      } catch (error) {
        console.error("Error calling OpenAI API:", error);
      }
    }
  }
  
  // async openaiText() {
  //   if (this.state.currentItem === "") {
  //     return null;
  //   } else {
  //     try {
  //       const response = await openai.chat.completions.create({
  //         model: "gpt-3.5-turbo",
  //         messages: [
  //           { role: "system", content: "You are a helpful assistant." },
  //           { role: "user", content: `(Write a complete sentence \n\n${this.state.currentItem} plan and assessment for this symptom, using a paragraph for the assessment and 3 bullet-style plans phrases, use a maximum of 500 words!)` },
  //         ],
  //         max_tokens: 60,
  //       });
  
  //       console.log("OpenAI API Response:", response);
  
  //       if (response.data && response.data.choices && response.data.choices.length > 0) {
  //         this.setState({ currentItem1: response.data.choices[0].text });
  //       } else {
  //         console.error("Invalid response from OpenAI API");
  //       }
  //     } catch (error) {
  //       console.error("Error calling OpenAI API:", error);
  //     }
  //   }
  // }
  

  // code get the label and searches for explanation
  handleItemClick = (item) => {
    this.setState({
      currentItem: item.label,
      currentItem5:item.label2,
      searchQuery: '',
      loading: true,
      alternatingColors: 'yellow',
      loadingText: 'Loading',

    }, async () => {
      await this.openaiText();
      this.setState({ loading: false });
    });
  };

  
  handleSearch(event) {
    this.setState({ searchQuery: event.target.value });
    // other logic for handling search
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  
// pushes it to the card
  handleSubmit(e) {
    // e.preventDefault();
    const itemsRef = firebase.database().ref('items');
    const item = {
      title: this.state.currentItem,
      hint: this.state.currentItem1,
      item5: this.state.currentItem5,
      selectedProp: this.state.selectedProp, // Include selectedProp
      user: this.state.user.displayName || this.state.user.email
    }
    itemsRef.push(item);
    this.setState({
      currentItem: '',
      currentItem1: '',
      currentItem5:'',
      username: ''
    });


   
      }

  // componentWillMount(props, state){
  //   this.setState({ showSmall: false });

  // }

  _isMounted = false;

  
  componentDidUpdate(prevProps) {
    if (this.props.selectedProp !== prevProps.selectedProp) {
      this.setState({ selectedProp: this.props.selectedProp }, () => {
        this.loadData(); // Call the necessary methods or update the state here
      });
    }
  }

  loadData() {
    // Load data based on the selectedProp
    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        if (items[item].selectedProp === this.state.selectedProp) {
          newState.push({
            id: item,
            title: items[item].title,
            hint: items[item].hint,
            user: items[item].user,
            item5: items[item].item5,
            selectedProp: items[item].selectedProp
          });
        }
      }
      this.setState({
        items: newState
      });
    });


  }

  componentDidMount(userId) {

    this._isMounted = true;

    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (this._isMounted && user) {
        this.setState({ user });
      }
    }); 
    //  auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   } 
    // });

  //now retrieves it from the database of the user nest
    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          hint: items[item].hint,
          user: items[item].user,
          item5: items[item].item5,
          selectedProp: items[item].selectedProp

        });

      }
      this.setState({
        items: newState
      });
    });

    
//retrieves from the unit database 
    const itemsRef1 = firebase.database().ref('0');
    itemsRef1.on('value', (snapshot) => {
      let ze = snapshot.val();
      let newState = [];
      for (let item in ze) {
        newState.push({
          id: item,
          label: ze[item].label,
          label2: ze[item].label2,

        });

      }
      this.setState({
        ze: newState
      });
    });

    this.colorInterval = setInterval(() => {
      this.setState((prevState) => ({
        alternatingColors: prevState.alternatingColors === 'orange' ? 'red' : 'orange',
        loadingText: prevState.loadingText === 'Loading...' ? 'Loading' : prevState.loadingText + '.',
      }));
    }, 500);

  }

  componentWillUnmount() {
    this._isMounted = false;
    // Unsubscribe from Firebase listener
    firebase.database().ref('items').off();
    firebase.database().ref('0').off();
    this.unsubscribeFromAuth();
    clearInterval(this.colorInterval);
  }

 
  removeItem(itemId) {
    const itemRef = firebase.database().ref(`/items/${itemId}`);
    itemRef.remove();
  }

  render() {
    const { focussed, value, error, label } = this.state;
    const { id, type, locked } = this.props;

    const styles = {
    
        alternatingColors: {
          animation: 'alternateColors 1s infinite',
          color: 'yellow',
        },
      
      slide: {
        fontSize: "12px",
        maxWidth: "100%",
        overflow: "hidden",
        display: "block",
        lineHeight: "1",
        border: "none",
        borderBottom: "none",
        outline: "none",
      },

      svg: {
        display: 'block',
        margin: '0 auto',
        left: '10px',
        top: '5px',
        bottom: '50px',
      },

      radio: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px', // Adjust the top padding to make the items thinner
        height: '20px', // Adjust the height of each item
        width: '100%', // Ensure the items have the same width

      },

      lists: {
        height: '20px',
        width: '300px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '12px',
        paddingLeft: '5px',
        color: 'grey',
        // width: '98%',
        marginLeft: '10px',
        marginTop: '20px', // Set the font color to grey
        whiteSpace: 'pre-wrap', // Allow wrapping at whitespace
        overflowWrap: 'break-word',
      },


      userinfo: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '10px',
        position: 'relative',
        border: '1px solid',
        width: '98%', 
        borderRadius: '25px',
        overflow: 'hidden' // Add this line
      },
      userInfoLine: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '1px',
        background: 'linear-gradient(to right, purple, green)'
      },
      Input: {
        fontSize: '12px',
        paddingLeft: '14px',
        cursor: 'pointer'
      }

    };
   


    return (


      <div className='' >
        {this.state.user ?
          <  >

<div className="card_item" style={styles.userinfo}> 
            <div className="UserInfo" >
            {/* <form
                        onSubmit={event => {
                          event.preventDefault(); // Prevent the default form submission behavior
                          this.openaiText();
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          maxWidth: '330px',
                          margin: '0 auto',
                          color: "black"
                        }}
                      >
                        <input
                          type="text"
                          name="currentItem"
                          placeholder="What is your diagnosis?"
                          onChange={this.handleChange}
                          value={this.state.currentItem}
                          style={{ flex: '1', marginRight: '0px', color: 'black' }}
                        />
                        <button
                          type="submit"
                          style={{ flex: '0 0 auto', marginBottom:'20px',  marginLeft: '5px', fontSize: '14px', width: '50px', alignItems: 'center', textAlign: 'center',   display: 'flex',
                          alignItems: 'center', justifyContent: 'center' }}
                        >
                          Submit
                        </button>
                      </form> */}
                      <div className="search-form">
                      <textarea
  placeholder="Search Diagnosis - Presenting Symptoms or history of Disease Conditions"
  value={this.state.currentItem.item ? this.state.currentItem.label : this.state.searchQuery}
  onChange={this.handleSearch}
  style={{
    color: 'black',
    fontSize: '13px',
    border: 'none',
    borderRadius: '5px', // <-- Add this line to make corners rounded
    boxShadow: '0 0 5px rgba(0,0,0,0.3)',
    resize: 'none', // <-- Add this line to disable resizing
    height: '50px',
    width: '330px',  // <-- Set width to 100% to match the input box
  }}
/>
  
<div className="search-results">
          {this.state.loading ? (
         <p
         style={{
           color: this.state.alternatingColors,
           fontFamily: 'Your Chosen Font, sans-serif', // Replace 'Your Chosen Font' with the actual font name
           fontSize: '16px', // Adjust the font size as needed
         }}
       >
         {this.state.loadingText.split('').map((char, index) => (
           <span key={index} style={{   color: this.state.alternatingColors, }}>
             {char}
           </span>
         ))}
       </p>
          ) : (
            this.state.ze
              .filter(
                (item) =>
                  this.state.searchQuery &&
                  item.label &&
                  item.label.toLowerCase().includes(this.state.searchQuery.toLowerCase())
              )
              .slice(0, 10)
              .map((item) => (
                <div key={item.id} onClick={() => this.handleItemClick(item)}>
                  <p style={{ color: 'black' }}>{item.label}</p>
                </div>
              ))
          )}
        </div>
</div>
<textarea
          style={{
            color: 'black',
            height: '150px',
            width: '330px',
            fontSize: '16px',
            padding: '10px',
            borderRadius: '5px',
            paddingBottom: '110px',
            paddingLeft: '10px',
            border: 'none',
            boxShadow: '0 0 5px rgba(0,0,0,0.3)',
            resize: 'none',
          }}
          name="currentItem1"
          placeholder="Assessment and Plan?"
          onChange={this.handleChange}
          value={this.state.currentItem1}
        />
              {/* <h3 onClick={() => this.setState({ modalIsOpen: true })}>template</h3> */}
              <button
                className="badge badge-info"
                style={{
                  backgroundColor: '#852d4a',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  border: 'none',
                  marginTop: '20px',
                  cursor: 'pointer'
                }}
                
                onClick={this.handleSubmit}
              >
                Add Item
              </button>
            </div>
            </div>

           

            {/* <Leftside001 selectedProp={this.props.selectedProp} /> */}

            
          </> :
          <p>.</p>
        }

      </div>

      // </WebView>  </view>



    );


  }
}
export default SubjectiveTab;


