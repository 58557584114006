import React, { Component } from "react";
import { Menu } from "antd";

import { BrowserRouter as Router, NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { HomeOutlined, TeamOutlined, SearchOutlined, BarChartOutlined } from "@ant-design/icons";
import firebase, { auth, provider } from '../firebase.js';


class SideMenu extends React.Component {
  constructor(props) {
    super();
      this.state = {
        currentItem: '',
        currentItem1: '',
        username: '',
        items: [],
        user: {
          name: null,
          isNameValid: null,
          email: null,
          isEmailValid: null
        }
      
  }
  }

  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
    });

  }

  componentWillUnmount() {
    firebase.database().ref('users').off();
    this.unsubscribeFromAuth();
  }


  render() {
    const { location } = this.props;
    const ToBuy2 = () => (
      <>
     
          
      
        <Slider
       imageSrc={travel_05}
          title={<span style={{ color: 'white' }}></span>}
          subtitle={<span style={{ color: 'white' }}></span>}
         
        />
       
      </>
    );
    return (
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["/"]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <HomeOutlined />
          <span>Home</span>
          <NavLink to="/" />
        </Menu.Item>
        <Menu.Item key="/Start">
          <TeamOutlined />
          <span>Start Chart</span>
          <NavLink to="/Start" />
        </Menu.Item>
        <Menu.Item key="/visit">
          <SearchOutlined />
          <span>Choose Visit Dialog</span>
          <NavLink to="/visit" />
        </Menu.Item>
        <Menu.Item key="/statistics">
          <BarChartOutlined />
          <span>Statistics</span>
          <NavLink to="/statistics" />
        </Menu.Item>
      </Menu>
      
    );
  }
}

export default withRouter(SideMenu);
