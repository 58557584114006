import React, { Component } from 'react';
// import styled from "styled-components";
import '../App.css';
import unsplash from '../images/unsplash.jpg'
import '../Modal.css';
import firebase, { auth, provider } from '../firebase.js';
import Modal from 'react-modal';
import swal from 'sweetalert';



class Meds extends Component {

  constructor(props) {
    super();
    this.state = {
      currentItem: '',
      currentItem1: '',
      allergies:'',
      pmh:'',
      psh:'',
      famhx:'',
      sochx:'',
      phq:'',
      functional:'',
      cogn:'',
      diet:'',
      excerise:'',
      Alcohol:'',
      smoking:'',
      vaccinations:'',
      medications:'',
      health:'',
      username: '',
      Left004: [],
      Left005: [],
      Left006: [],
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
    },
     modelText01: '',
     modalIsOpen: false,
     modalIsOpen0: false,
     modalIsOpen1: false,
     displayButtonClicked: true,
     displayButtonClicked2: true,
     displayButtonClicked3: true,
  
    
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit0 = this.handleSubmit0.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
  
    // this.handleInputChange01 = this.handleInputChange01.bind(this);
   
  }
  // handleInputChange01(event) {
  //   this.setState({ modelText01: event.target.value });
  // }

  handleDisplayMessages = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked: true
      });
    }
  }
  

  
  };

  handleDisplayMessages2 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked2) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked2: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked2: true
      });
    }
  }
  
  };

  handleDisplayMessages3 = () => {

    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {

    if (this.state.displayButtonClicked3) {
      // If the button is already clicked, set the state to false to hide the content
      this.setState({
        displayButtonClicked3: false
      });
    } else {
      // If the button is not clicked, set the state to true to display the content
      this.setState({
        displayButtonClicked3: true
      });
    }
  }
  
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
 
  
  handleSubmit(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
  
    const Left1Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left006`);
  
    const item1 = {
     smoking:this.state.smoking,
     user: this.state.user.displayName || this.state.user.email,
     selectedProp: this.props.selectedProp
   }
  
    Left1Ref.push(item1);
    this.setState({
      
        smoking:'',
        

      username: ''
    });
  }
  }

  
  handleSubmit0(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
    const LeftRef = firebase.database().ref(`users/${this.state.user.displayName}/Left004`);
   
    const item = {
     excerise:this.state.excerise,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }
  
    LeftRef.push(item);
 
    this.setState({
        excerise:'',
        username: ''
     
    });
  }
  }

  handleSubmit1(e) {
    if (this.props.selectedProp === '') {
      // Display an error message or perform any other action
      console.log('Please select a chart type.');
      // window.alert('Please select a chart type.');
      swal("Oops", "Please select a chart type!", "error")
      return;
    }
    else {
    const Left0Ref = firebase.database().ref(`users/${this.state.user.displayName}/Left005`);
       const item0 = {
        Alcohol:this.state.Alcohol,
      user: this.state.user.displayName || this.state.user.email,
      selectedProp: this.props.selectedProp
    }
    

    Left0Ref.push(item0);
   
    this.setState({
      
        Alcohol:'',
           

      username: ''
     
    });
  }
  }

  // componentWillMount(props, state){
  //   this.setState({ showSmall: false });

  // }
  

  _isMounted = false;


  componentDidMount(userId) {

    this._isMounted = true;

    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (this._isMounted && user) {
        this.setState({ user });
      }
    }); 
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   } 
    // });
  
       
    const LeftRef = firebase.database().ref('users');
    LeftRef.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left004) {
          let Left004 = user.Left004;
          for (let itemId in Left004) {
            let item = Left004[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              excerise: item.excerise,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left004: newState
      });
    });

   
    const Left0Ref = firebase.database().ref('users');
    Left0Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left005) {
          let Left005 = user.Left005;
          for (let itemId in Left005) {
            let item = Left005[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              Alcohol: item.Alcohol,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left005: newState
      });
    });
    
    const Left1Ref = firebase.database().ref('users');
    Left1Ref.on('value', (snapshot) => {
      let users = snapshot.val();
      let newState = [];
      for (let userId in users) {
        let user = users[userId];
        if (user.Left006) {
          let Left006 = user.Left006;
          for (let itemId in Left006) {
            let item = Left006[itemId];
            newState.push({
              userId: userId,
              itemId: itemId,
              smoking: item.smoking,
              user: item.user,
              selectedProp: item.selectedProp
            });
          }
        }
      }
      this.setState({
        Left006: newState
      });
    });
   
  }

  handleEditChange(event, itemId) {
    const { value } = event.target;
    this.setState((prevState) => ({
      Left004: prevState.Left004.map((item) => {
        if (item.itemId === itemId) {
          return { ...item, excerise: value };
        }
        return item;
      }),
    }));
  }
  
  startEditItem(itemId) {
    this.setState({ editingItemId: itemId });
  }
  
  submitEditItem(itemId) {
    const updatedExcerise = this.state.Left004.find((item) => item.itemId === itemId).excerise;
    const itemRef = firebase.database().ref(`users/${this.state.user.displayName}/Left004/${itemId}`);
    
    itemRef.update({ excerise: updatedExcerise })
      .then(() => {
        console.log('Item updated successfully.');
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.error('Error updating item:', error);
      });
  }

  
  handleEditChange2 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left005: prevState.Left005.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            Alcohol: updatedValue,
          };
        }
        return item;
      }),
    }));
  };
  
  startEditItem2 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };
  
  submitEditItem2 = (itemId) => {
    const updatedItem = this.state.Left005.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left005/${itemId}`);
    
    userRef.update({ Alcohol: updatedItem.Alcohol })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };

  handleEditChange3 = (event, itemId) => {
    const updatedValue = event.target.value;
    this.setState((prevState) => ({
      Left006: prevState.Left006.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            smoking: updatedValue,
          };
        }
        return item;
      }),
    }));
  };
  
  startEditItem3 = (itemId) => {
    this.setState({
      editingItemId: itemId,
    });
  };
  
  submitEditItem3 = (itemId) => {
    const updatedItem = this.state.Left006.find((item) => item.itemId === itemId);
    const userRef = firebase.database().ref(`users/${updatedItem.userId}/Left006/${itemId}`);
  
    userRef.update({ smoking: updatedItem.smoking })
      .then(() => {
        console.log("Item updated successfully");
        this.setState({ editingItemId: null });
      })
      .catch((error) => {
        console.log("Error updating item:", error);
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
    // Unsubscribe from Firebase listener
    firebase.database().ref('users').off();
    this.unsubscribeFromAuth();
  }
  
 
  removeItem(userId, itemId) {
    const itemRef = firebase.database().ref(`users/${userId}/Left004/${itemId}`);
    itemRef.remove();

    const itemRef0 = firebase.database().ref(`users/${userId}/Left005/${itemId}`);
    itemRef0.remove();

    const itemRef1 = firebase.database().ref(`users/${userId}/Left006/${itemId}`);
    itemRef1.remove();
  }
  
  render() {
    const { focussed, value, error, label } = this.state;
    const { id, type, locked } = this.props;
    const { displayButtonClicked } = this.state;
    const { displayButtonClicked2 } = this.state;
    const { displayButtonClicked3 } = this.state;
    const styles = {

      slide: {
        fontSize: "12px",
        maxWidth: "100%",
        overflow: "hidden",
        display: "block",
        lineHeight: "1",
        border: "none",
        borderBottom: "none",
        outline: "none",
      },

      svg: {
        display: 'block',
        margin: '0 auto',
        left: '10px',
        top: '5px',
        bottom: '50px',
      },

      radio: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px', // Adjust the top padding to make the items thinner
        height: '30px', // Adjust the height of each item
        width: '100%', // Ensure the items have the same width
      },

      lists: {
        height: '20px',
        width: '300px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '12px',
        paddingLeft: '5px',
        color: 'grey',
        // width: '98%',
        marginLeft: '10px',
        marginTop: '20px', // Set the font color to grey
        whiteSpace: 'pre-wrap', // Allow wrapping at whitespace
        overflowWrap: 'break-word',
      },

      
      userinfo: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '10px',
        position: 'relative',
        border: '1px solid',
        width: '98%', 
        borderRadius: '25px',
        overflow: 'hidden' // Add this line
      },
      userInfoLine: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '1px',
        background: 'linear-gradient(to right, purple, green)'
      },

      Input: { 
        fontSize: '12px', 
      paddingLeft: '14px', 
      cursor: 'pointer' }

    };


    const Leftside2 = () => {
      return (
        <div className="Container">
               
              <div className="CommunityCard">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <section className='add-item2'> 
              
                          
              </section>
              
              </div>
              
                
              </div>
            </div>
      );
    
    
      };
      
   return (

    
  <div>
  
    {this.state.user ?
       <div >
       <div className="UserInfo" style= {styles.userinfo}>
            <div style={styles.userInfoLine}></div> 
             <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
               
      <label htmlFor="excerise" onClick={() => swal("Excerise", "... e.g. Type of Exercises and timing - Days/weeks for how many minutes, e.g Walks, Gym or Home workouts et.c ")}>Exercise:</label>
      <input
        id="excerise"
        type="text"
        placeholder="Enter your Exercise assessment here"
        name="excerise"
        onChange={this.handleChange}
        value={this.state.excerise} 
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit0();
          }
        }} 
        style={styles.lists}
      />
<div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>

    </form>
    
        {/* <button onClick={() => this.setState({ modalIsOpen: true })}>Open Modal</button> */}
      
        {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen} onRequestClose={() => this.setState({ modalIsOpen: false })}>
  <h2 key="modalTitle">Excerise</h2>
  <div className="search-results">
    {this.state.Left004
    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
    .map((item) => (
      <React.Fragment key={item.itemId}>
        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })} 
  onClick={() => this.removeItem(item.userId, item.itemId)} />
       
        <div style={{ fontSize: "12px", paddingLeft: "140" }}>
          {item.excerise}
        </div>
        </div>
      </React.Fragment>
    ))}
  </div>
</Modal> */}
{displayButtonClicked && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left004
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.excerise}
                              onChange={(event) => this.handleEditChange(event, item.itemId)}
                              onKeyPress={event => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem(item.itemId);
                                }
                              }}
                               style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem(item.itemId)}
                            >
                              {item.excerise}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}

    
    
    
      </div>
           
           <div className="UserInfo" style= {styles.userinfo}>
                     <div style={styles.userInfoLine}></div> 
              <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
                 
             <label htmlFor="Alcohol" onClick={() => swal("Alcohol", "... Complete with how much of drinking (frequency e.g. per week, per month or none), How many drinks per occassion and How many times of having more than 6 drinks per occassion")}>Alcohol:</label>
      <input
        id="Alcohol"
        type="text"
        placeholder="Enter your Alcohol assesment"
        name="Alcohol"
        onChange={this.handleChange}
        value={this.state.Alcohol} 
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit1();
          }
        }} 
        style={styles.lists}
 />
<div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages2}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>

    </form>

           {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen0} onRequestClose={() => this.setState({ modalIsOpen0: false })}>
  <h2 key="modalTitle">Alcohol</h2>
  <div className="search-results">
    {this.state.Left005
    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
    .map((item) => (
      <React.Fragment key={item.itemId}>
        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })} 
  onClick={() => this.removeItem(item.userId, item.itemId)} />
       
        <div style={{ fontSize: "12px", paddingLeft: "140" }}>
          {item.Alcohol}
        </div>
        </div>
      </React.Fragment>
    ))}
  </div>
</Modal> */}
  {displayButtonClicked2 && ( // Only show the resume button if display button is not clicked
                <div >
                  {this.state.Left005
                    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
                    // .filter(item => item.selectedProp === this.props.selectedProp)
                    .map((item) => (
                      <React.Fragment key={item.itemId}>
                        <div
                          key={item.id}
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
                        >
                          <input
                            type="radio"
                            name="radio-button"
                            checked={false}
                            onChange={() => this.setState({ selectedRadio: item.id })}
                            onClick={() => this.removeItem(item.userId, item.itemId)}
                          />
                            {this.state.editingItemId === item.itemId ? (
                            <textarea
                              type="text"
                              value={item.Alcohol}
                              onChange={(event) => this.handleEditChange2(event, item.itemId)}
                              onKeyPress={event => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                  event.preventDefault();
                                  this.submitEditItem2(item.itemId);
                                }
                              }}
                               style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
                            />
                          ) : (
                            <div
                              style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                              onClick={() => this.startEditItem2(item.itemId)}
                            >
                              {item.Alcohol}
                            </div>
                          )}

                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}
  </div>
           
           <div className="UserInfo" style= {styles.userinfo}>
                     <div style={styles.userInfoLine}></div> 
    <form style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: 'white', position: 'relative' }}>
             <label htmlFor="smoking" onClick={() => swal("Smoking", "...Smoking history - Never, Former or Current Tobacco user. May clearify if a cigar smoker and how often tobacco use ")}>Smoking:</label>
             <input
        id="smoking"
        type="text"
        placeholder="Enter your Smoking assessment here"
        name="smoking"
        onChange={this.handleChange}
        value={this.state.smoking} 
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit();
          }
        }} 
        style={styles.lists}
      />
       <div style={{ position: 'absolute', bottom: '23px', right: '5px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="light blue"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                      style={styles.svg}
                      onClick={this.handleDisplayMessages3}
                    >
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                  </div>
    </form>

          {/* <Modal className="modalContainer" isOpen={this.state.modalIsOpen1} onRequestClose={() => this.setState({ modalIsOpen1: false })}>
  <h2 key="modalTitle">Smoking</h2>
  <div className="search-results">
    {this.state.Left006
    .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
    .map((item) => (
      <React.Fragment key={item.itemId}>
        <div key={item.id} style={{ display: 'flex', alignItems: 'center', paddingTop: '18px' }}>
          <input type="radio" name="radio-button" checked={false} onChange={() => this.setState({ selectedRadio: item.id })} 
  onClick={() => this.removeItem(item.userId, item.itemId)} />
       
        <div style={{ fontSize: "12px", paddingLeft: "140" }}>
        {item.smoking}
        </div>
        </div>
        </React.Fragment>
    ))}
  </div>
</Modal> */}
{displayButtonClicked3 && (
  <div>
    {this.state.Left006
      .filter(item => item.userId === (this.state.user.displayName || this.state.user.email))
      // .filter(item => item.selectedProp === this.props.selectedProp)
      .map((item) => (
        <React.Fragment key={item.itemId}>
          <div
            key={item.itemId}
            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'  }}
          >
            <input
              type="radio"
              name="radio-button"
              checked={false}
              onChange={() => this.setState({ selectedRadio: item.itemId })}
              onClick={() => this.removeItem(item.userId, item.itemId)}
            />
            {this.state.editingItemId === item.itemId ? (
              <textarea
                type="text"
                value={item.smoking}
                onChange={(event) => this.handleEditChange3(event, item.itemId)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    this.submitEditItem3(item.itemId);
                  }
                }}
                 style={{ background: '#FFFF99', color: 'black', width: '300px', height: '100px', resize: 'none',  borderRadius: '5px' }}
              />
            ) : (
              <div
                style={{ fontSize: '12px', paddingLeft: '14px', cursor: 'pointer' }}
                onClick={() => this.startEditItem3(item.itemId)}
              >
                {item.smoking}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
  </div>
)}
   </div> 


    <a>

</a>








</div>

: 
<p>You must be logged in to see the potluck list and submit to it.</p>
}

 </div>
    // </WebView>  </view>
     
      
      
      ); 

   
  }
}
export default Meds;

