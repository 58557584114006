import React, {  Component } from 'react';
import { useState, useEffect } from 'react';
// import styled from "styled-components";
import '../App.css';
import unsplash from '../images/unsplash.jpg'
import axios from 'axios';
// import Header001 from './Header001';
import OpenAI from 'openai';



const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
// Instantiate OpenAI with apiKey
const openai = new OpenAI({ apiKey, engine: "text-davinci-003", dangerouslyAllowBrowser: true  });

// const Subjective = () => {

//   const [userText, setUserText] = useState('');
//   const [modelText, setModelText] = useState('');
//   const [similarityScore, setSimilarityScore] = useState(0);

//   const [responseData, setResponseData] = useState(null);

//   async function compareText() {
//     const response = await openai.createCompletion({
//       model: "text-davinci-003",
//        prompt: `if using a Load the pre-trained BERT model and tokenizer,
//       calculate the similarity = compute_similarity( \n\n${modelText}.join(' '), \n\n${userText}.join(' ')): 
//       than answer the Math.round(similarity * 100) as a percentage, only!`, // Expect a philosophical response    
// });

//     setSimilarityScore(response.data.choices[0].text);
  
//   }
  const Subjective = () => {
  const [userText, setUserText] = useState('');
  const [modelText, setModelText] = useState('');
  const [similarityScore, setSimilarityScore] = useState(0);
  
  const [responseData, setResponseData] = useState(null);
  
  async function compareText() {
    if (!userText || !modelText) {
      // Handle empty inputs
      return;
    }

    try {
      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: "You are a helpful assistant." },
          { role: "user", content: userText },
          { role: "assistant", content: modelText },
          { role: "user", content: `if using a Load the pre-trained BERT model and tokenizer,
            calculate the similarity = compute_similarity( \n\n${modelText}.join(' '), \n\n${userText}.join(' ')): 
            then answer the Math.round(similarity * 100) as a percentage, only!` },
        ],
      });

      const similarityText = response.choices[0].message.content;
      const similarityValue = parseInt(similarityText, 10); // Assuming the similarity value is a number

      setSimilarityScore(similarityValue);
      setResponseData(response);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
    }
  }

  return (
    
 
 
    <div className="UserInfo" >
  
    
        <div className="AddPhotoText">e-chart</div>
   
  

    
 
        <img src="/images/item-icon.svg" alt="" />
      <h1> History of Presenting Illness</h1>

 
   
       
   
        <textarea
        style={{
          
          height: '250px',
          width: '330px',
          fontSize: '16px',
          padding: '1px',
          borderRadius: '5px',
          paddingBottom: '110px',
          paddingLeft: '10px',
          border: 'none',
          boxShadow: '0 0 5px rgba(0,0,0,0.3)',
          resize: 'none', // <-- Add this line to disable resizing
          display: 'none' 
        }}
        placeholder="Model Text"
        value={modelText}  onChange={(event) => setModelText(event.target.value)}/>
     
 <div>  
 <textarea
 style={{
  height: '250px',
  width: '330px',
  fontSize: '16px',
  padding: '1px',
  borderRadius: '5px',
  paddingBottom: '110px',
  paddingLeft: '1px',
  border: 'none',
  boxShadow: '0 0 5px rgba(0,0,0,0.3)',
  resize: 'none' // <-- Add this line to disable resizing
}}
  name="currentItem"
  placeholder="HPI"

  value={userText} onChange={(event) => setUserText(event.target.value)}
/>
        
      </div>
      /// Add HPI and complete with summary of medical conditions, name of patient, background details and acute complaints including any other purposes for the visit.
            {/* <button onClick={compareText} >Compare</button> */}
      {/* <p>Similarity Score: {similarityScore}%</p> */}
      {/* <p>Similarity Score: {parseFloat(similarityScore).toFixed(0)}%</p> */}
      
      <div>
      <textarea
        value={userText}
        onChange={(e) => setUserText(e.target.value)}
        placeholder="Enter user text"
      />
      <textarea
        value={modelText}
        onChange={(e) => setModelText(e.target.value)}
        placeholder="Enter model text"
      />
      <button onClick={compareText}>Compare Texts</button>

      {responseData && (
        <div>
          <p>Similarity Score: {similarityScore}%</p>
          <p>Full Response: {JSON.stringify(responseData)}</p>
        </div>
      )}
    </div>
  </div>

 
  );


  };




export default Subjective;