// import styled from "styled-components";
import React, { Component } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';

// import WebView from 'react'
// import unsplash from '../images/unsplash.jpg'
// import Leftside from '../Leftside';
// import Rightside from '../Rightside';
// import Leftside001 from '../Leftside001';

// // import Header001 from './Header001';
// // import Side from './Side';
// import Subjective from '../SOAP/Subjective';
// import Objective from '../SOAP/Objective';
// import Header from '../Components/Header';
import SubjectiveTab from './SubjectiveTab';
import SwipeableViews from 'react-swipeable-views';
// import Login from '../Login';
import ObjectiveTab from './ObjectiveTab';
import Assessment from '../SOAP/Assessment';
import Assessment2 from '../SOAP/Assessment2';
import Assessment3 from '../SOAP/Assessment3';

// import Centering4Ways from '../Components/menu';
// import Header001 from './Header001';
import { connect } from 'react-redux';


const mapStateToProps = state => ({ ...state.quiz, ...state.mode, ...state.pager });

const mapDispatchToProps = dispatch => ({
    onAnswer: payload => dispatch({ type: ActionTypes.QuizAnswer, payload })
});


class Body extends Component {

  constructor(props) {
    super();
    this.state = {
      currentItem: '',
      currentItem1: '',
      username: '',
      items: [],
      user: {
        name: null,
        isNameValid: null,
        email: null,
        isEmailValid: null
      }
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.state = {index: 0};
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }


  handleSubmit(e) {
    e.preventDefault();
    const itemsRef = firebase.database().ref('items');
    const item = {
      title: this.state.currentItem,
      hint: this.state.currentItem1,
      user: this.state.user.displayName || this.state.user.email
    }
    itemsRef.push(item);
    this.setState({
      currentItem: '',
      currentItem1: '',
      username: ''
    });
  }

  //Swipeables

  handleChange = (event, value) => { this.setState({ index: value }); };

  // handleChange2 = (event, value) => {this.setState({index: 0});};

  handleChangeIndex = index => {
    this.setState({ index })
      ;
  }

  handleUnmount = () => {
    this.setState({ index: null });
  };

  //Swipeables
  _isMounted = false;


  componentDidMount(userId) {

    this._isMounted = true;

    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (this._isMounted && user) {
        this.setState({ user });
      }
    });
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     //single login with token
    //     this.setState({ user });
    //     //everytime the user logs in(multiple), we will get the user's data
    //     // this.setState1({ user });
    //   }
    // });

    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          hint: items[item].hint,
          user: items[item].user

        });

      }
      this.setState({
        items: newState
      });
    });


  }

  
  componentWillUnmount() {
    this._isMounted = false;
    // Unsubscribe from Firebase listener
    firebase.database().ref('items').off();

    this.unsubscribeFromAuth();
  }
  

  removeItem(itemId) {
    const itemRef = firebase.database().ref(`/items/${itemId}`);
    itemRef.remove();
  }

  render() {
    const { focussed, value, error, label } = this.state;
    const { id, type, locked } = this.props;
    const styles = {
      background: {
        backgroundImage: "url(https://cloudfront.v2com-newswire.com/data/photo/image/6419/preview_978-1_6419_sc_v2com.jpg)",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        paddingBottom: 24,
        display: "flex",
        alignItems: "flex-end",
        color: "#fff",
        fontSize: 24,
        fontWeight: "bold"
      },
      root: {
        borderRadius: '10px 10px 0 0',
        backgroundColor: '#252d4a',
        color: '#F3EFF5',
        width: 400,
        margin: "0 auto",
        textAlign: "center"
      },
      backdrop: {
        backgroundColor: 'rgba(255,255,255,0.8)',

      },
      title: {
               padding: '16px 0',
        boxSizing: 'border-box',
        color: 'white',
        fontSize: '24px',
        textAlign: 'center',
        display: 'flex',



      },
      text: {
        padding: '10px',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        fontSize: '18px',
      },

      slide: {
        padding: 15,
        minHeight: 35,
        color: '#fff',
      },
      slide1: {
        backgroundColor: '#FEA900',

      },
      slide2: {
        backgroundColor: '#852d4a',
        border: '#252d4a',
        boxShadow: 'none', '&:hover': {
          border: '1px solid black'

        }

      },
      slide3: {
        backgroundColor: '#252d4a',
        border: '#252d4a',
        boxShadow: 'none', '&:hover': {
          border: '1px solid black'

        }

      }
    };


    const { index } = this.state;



    return (
      <div  >

        {this.state.user ?

          <div className='container' >

            <SwipeableViews

              style={{ margin: '0px', scrollbar: 'none' }}
              enableMouseEvents
              index={index}
              onChangeIndex={this.handleChangeIndex}
            >

              <div key={index}  >

                <button className="badge badge-info" style={{
                  backgroundColor: '852d4a',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  border: 'none'
                }} onClick={(e) => { this.handleChange(e, 1); }}>Next</button>


                <SubjectiveTab  selectedProp={this.props.selectedProp}  />


              </div>
              <div > <button className="badge badge-info" style={{
                backgroundColor: 'blue',
                color: 'white',
                padding: '10px',
                borderRadius: '5px',
                border: 'none',
                marginRight: '10px'
              }} onClick={(e) => { this.handleChange(e, 0); }}> Back</button>
                <button className="badge badge-info" style={{
                  backgroundColor: '852d4a',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  border: 'none'
                }} onClick={(e) => { this.handleChange(e, 2); }}>Next</button>
                <ObjectiveTab />
              </div>

              <div>  <div  >
                <button className="badge badge-info" style={{
                  backgroundColor: 'blue',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  border: 'none',
                  marginRight: '10px'
                }} onClick={(e) => { this.handleChange(e, 1); }}> Back</button>  
                    <br></br>
    <br></br>

     <div>
     {this.props.selectedProp === 'new patient' ? (
  <Assessment />
) : this.props.selectedProp === 'annual' ? (
  <Assessment2 />
) : this.props.selectedProp === 'follow up' ? (
  <Assessment3 />
) : (
  <p>No assessment available for the selected prop.</p>
)}
    </div>
                {/* */}

              </div></div>

              <button className="badge badge-info" style={{
                backgroundColor: '852d4a',
                color: 'white',
                padding: '10px',
                borderRadius: '5px',
                border: 'none'
              }} onClick={(e) => { this.handleChange(e, 2); }}>Next</button>




            </SwipeableViews>

            <div className="Container">

              <div className="CommunityCard">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                  <section className='add-item2'>


                  </section>

                </div>


              </div>
            </div>




            {/* <div className='user-profile'> */}

            {/* </div> */}



            {/*  
     <form action="http://localhost:5000/search" method="get">
     <li> Symptoms: <input type="submit" value="Submit"/></li> 
   
      
  </form>
  <form action="http://localhost:5000/research" method="get">
      <li>Disease:  &nbsp;&nbsp;&nbsp; <input type="submit" value="Submit"/></li> 
      
     
  </form> */}
            {/* <input type="text" name=""/> */}
            {/* <input type="text" name=""/> */}


            {/* <div className='user-profile'>
        <img src={this.state.user.photoURL} />
    </div> */}



          </div>
          :
          <p>You must be logged in to see the potluck list and submit to it.</p>
        }


      </div>


    );


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Body);


