import React, { useState } from "react";
import "./Slider.css";
import { useInView } from "react-intersection-observer";

const SliderErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  return hasError ? null : children(handleError);
};

const Slider = ({ imageSrc, title, subtitle, flipped }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  const renderContent = () => {
    if (!flipped) {
      return (
        <>
          <img src={imageSrc} alt="Travel" className="slider__image" />
          <div className="slider__content">
            <h1 className="slider__title">{title}</h1>
            <p>{subtitle}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="body-content">
            <h1 className="slider__title">{title}</h1>
            <p>{subtitle}</p>
          </div>
          <img src={imageSrc} alt="Travel" className="slider__image" />
        </>
      );
    }
  };

  return (
    <SliderErrorBoundary>
      {(handleError) => (
        <div className={inView ? "slider slider--zoom" : "slider"} ref={ref}>
          {renderContent()}
        </div>
      )}
    </SliderErrorBoundary>
  );
};

export default Slider;
