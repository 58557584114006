import React from 'react';
import YouTube from 'react-youtube';
import './YouTubeShortVideo.css'; // Create a CSS file for styling

const YouTubeShortVideo = ({ videoId }) => {
  const opts = {
    height: '1080',
    width: '1920',
    playerVars: {
      // Add any necessary player options or parameters here
    },
  };

  const handlePlayerReady = (event) => {
    // Perform any actions when the player is ready
  };

  const handlePlayerStateChange = (event) => {
    // Perform any actions when the player state changes
  };

  return (
    <div className="youtube-short-video">
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={handlePlayerReady}
        onStateChange={handlePlayerStateChange}
      />
    </div>
  );
};

export default YouTubeShortVideo;
