import React from "react";
import { Tabs, Menu, Dropdown } from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  SearchOutlined,
  BarChartOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";

const { TabPane } = Tabs;

const iconStyle = { fontSize: "22px" };

const TabMenu = () => {
  const history = useHistory();
  const location = useLocation();

  const handleTabClick = (key) => {
    console.log(`Navigating to ${key}`);
    history.push(key);
  };

  const handleMenuClick = (submenuKey) => {
    console.log(`Navigating to /charts/${submenuKey}`);
    history.push(`/charts/${submenuKey}`);
  };

  const chartsMenu = (
    <Menu onClick={({ key }) => handleMenuClick(key)} style={{background: '#252d4a' }}>
      <Menu.Item key="vitals">Vitals</Menu.Item>
      <Menu.Item key="hpi">HPI</Menu.Item>
      {/* Add more submenu items as needed */}
    </Menu>
  );

  return (
    <Tabs
      activeKey={location.pathname}
      onTabClick={handleTabClick}
      style={{
        display: "flex",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 900,
        backgroundColor: "transparent",
        justifyContent: "center", // Center the tabs
        alignItems: "center",
      }}
      tabPosition="bottom" // Set the tab position to bottom
    >
      <TabPane
        tab={
          <span>
            <HomeOutlined style={iconStyle} />
            <div>Home</div> {/* Title below the icon */}
          </span>
        }
        key="/"
      ></TabPane>

      <TabPane
        tab={
          <span>
            <TeamOutlined style={iconStyle}  />
            <Dropdown overlay={chartsMenu} trigger={["click"]}>
              <div>
                <div>Charts</div>
                <CaretDownOutlined style={{ marginLeft: "5px" }} />
              </div>
            </Dropdown>
          </span>
        }
        key="/Start"
      ></TabPane>

      <TabPane
        tab={
          <span>
            <SearchOutlined style={iconStyle} />
            <div>Dialog</div> {/* Title below the icon */}
          </span>
        }
        key="/visit"
      ></TabPane>

      <TabPane
        tab={
          <span>
            <BarChartOutlined style={iconStyle} />
            <div>Statistics</div> {/* Title below the icon */}
          </span>
        }
        key="/statistics"
      ></TabPane>
    </Tabs>
  );
};

export default withRouter(TabMenu);
