import React, { Component, useState } from 'react';
import { authBackend } from "./backend/Auth";
// import {login3 } from "./App";
import Register from "./Register";
import { BrowserRouter, Route, Link } from 'react-router-dom';
// import { firebase } from "../../../../FirebaseAuth/Firebase";
// reactstrap components
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";


// import { firebase } from "../../../../FirebaseAuth/Firebase";
import { Link as ReactRouterLink } from 'react-router-dom';

const CustomLink = ({ to, children, ...props }) => {
  // Customize your link here if needed
  return <ReactRouterLink to={to} {...props}>{children}</ReactRouterLink>;
};
const Login = props => {
  const [email, setEmail] = useState("abc@abc.com");
  const [password, setPassword] = useState("");
  const [rememberCheckbox, setCheckbox] = useState(false);
  return (
    <>
      <Col lg="14" md="12">
        <Card className="bg-secondary shadow border-0">
          {/* <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                 onClick={login}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    // src={require("assets/img/icons/common/google.svg")}
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader> */}
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={e => e.preventDefault() && false}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <Input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                  value={rememberCheckbox}
                  onChange={e => {
                    setCheckbox(!rememberCheckbox);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={login2}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/register"
              onClick={login3}
            >
              <small>Create new account</small>
    
       
            </a>
          </Col>          
        </Row>
      </Col>
    </>
  );

  async function login2() {
    try {
      await authBackend.Login(email, password);
      props.history.replace("/start");
    } catch (error) {
      alert(error.message);
    }
  }

  async function login3() {
    try {
      <CustomLink  to="/register"  style={{ marginLeft: '30px'}} />
    } catch (error) {
      alert(error.message);
    }
  }

  // async function login() {
  //   try {
  //     await login3();
  //     // props.history.replace("/ss");
  //   } catch (error) {
  //     alert(error.message);
  //   }
  // }
};
export default withRouter(Login);
